import React from 'react';
import { Text } from '@fluentui/react';
import moment from 'moment';
import { FormErrors } from '../../types';

import {
	Container,
	CardHeader,
	HeaderText,
	CheckboxButton,
	CardBody,
	Field,
	PixIcon,
} from './style';

interface IPixPaymentProps {
	handlePaymentMethods: (checked: any, newState: string) => void;
	setPaymentType: (id: string, value: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	formErrors: FormErrors;
	paymentType: any;
	paymentMethods: string[];
}

const PixCard: React.FunctionComponent<IPixPaymentProps> = ({
	handlePaymentMethods,
	setPaymentType,
	setFormErrors,
	paymentType,
	formErrors,
	paymentMethods,
}) => {
	let hasPix = paymentMethods && paymentMethods.includes('pix');

	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	return (
		<Container hasPix={hasPix}>
			<CardHeader>
				<CheckboxButton
					checked={hasPix}
					onChange={(e, value) => handlePaymentMethods(value, 'pix')}
				/>
				<HeaderText>
					<PixIcon src="/icons/icone-pix.png" />
					<Text>Pix</Text>
				</HeaderText>
			</CardHeader>
			<CardBody>
				<Text>Expira em (*)</Text>
				{/* <Field
          disabled={!hasPix}
          mask='999'
          value={paymentType?.expiresIn ?? ''}
          onFocus={() => clearFormErrors('expiresIn')}
          onChange={(e, value) => setPaymentType('expiresIn', value)}
          error={formErrors.expiresIn}
          errorMessage='Data inválida'
        /> */}
				<Field
					disabled
					mask="99/99/9999"
					placeholder="dd/mm/aaaa"
					value={paymentType?.expiresAt ?? ''}
					error={formErrors.expiresAt}
					errorMessage="Data inválida"
					onFocus={() => clearFormErrors('expiresAt')}
					onChange={(e, value) => setPaymentType('expiresAt', value)}
				/>
				{/* <Text variant="small">
					Deve ser maior que a expiração do link (
					{moment().format('DD/MM/YYYY')})
				</Text> */}
			</CardBody>
		</Container>
	);
};

export default PixCard;
