import React from 'react';
import { Text } from '@fluentui/react';
import { Container, ErrorText, ErrorIcon } from './style';

interface INothingFoundProps {}

const NothingFound: React.FunctionComponent<INothingFoundProps> = () => {
	return (
		<Container>
			<ErrorIcon aria-label="Error" iconName="Error" />
			<ErrorText>Não encontramos nenhum pedido para esta busca</ErrorText>
			<Text>
				Verifique se você digitou o código de referência do pedido corretamente.
			</Text>
		</Container>
	);
};

export default NothingFound;
