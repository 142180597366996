import { Dispatch } from 'redux';
import { GetOrdersTypes } from './types';
import { getOrders, getOrderByCode, getOrderById } from '../../services/order';
import { OrderListData } from '../../../pages/admin/orders/types';

/**
 * Capturar o total de pedidos para paginacao
 */
export const getTotalOrders = (data: number) => ({
	type: GetOrdersTypes.SET_TOTAL_ORDERS,
	payload: data,
});

/**
 * Capturar o filtro de status
 */
export const setStatusFilter = (data: string) => ({
	type: GetOrdersTypes.SET_STATUS,
	payload: data,
});

/**
 * Capturar o codigo do pedido
 */
export const setOrderCode = (data: string) => ({
	type: GetOrdersTypes.SET_ORDER_CODE,
	payload: data,
});

/**
 * Capturar a data de inicio para filtragem
 */
export const setCreatedSince = (data: string) => ({
	type: GetOrdersTypes.SET_CREATED_SINCE,
	payload: data,
});

/**
 * Capturar data final para filtragem
 */
export const setCreatedUntil = (data: string) => ({
	type: GetOrdersTypes.SET_CREATED_UNTIL,
	payload: data,
});

/**
 * Limpar filtragem de status
 */
export const clearStatusFilter = () => ({
	type: GetOrdersTypes.CLEAR_STATUS_FILTER,
	payload: '',
});

/**
 * Limpar filtragem de data
 */
export const clearCreatedSince = () => ({
	type: GetOrdersTypes.CLEAR_CREATED_SINCE,
	payload: '',
});

/**
 * Limpar filtragem de data
 */
export const clearCreatedUntil = () => ({
	type: GetOrdersTypes.CLEAR_CREATED_UNTIL,
	payload: '',
});

/**
 * Limpar filtragem de codigo do pedido
 */
export const clearOrderCode = () => ({
	type: GetOrdersTypes.CLEAR_ORDER_CODE,
	payload: '',
});

/**
 * Requisicao GET de todos os pedidos na pagarme
 * @param {any} page | Numero da pagina
 * @param {string} code | Codigo de um pedido especifico
 * @param {string} status | Status para filtragem
 * @param {any} createdSince | Data inicio para filtragem
 * @param {any} createdUntil | Data final para filtragem
 */
export const getOrdersList =
	(
		code: string,
		page: any,
		status: string,
		createdSince: any,
		createdUntil: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: GetOrdersTypes.GET_ORDERS_START,
			});

			const { data, paging } = await getOrders(
				page,
				code,
				status,
				createdSince,
				createdUntil
			);

			dispatch({
				type: GetOrdersTypes.GET_ORDERS_SUCCESS,
				payload: data,
			});

			dispatch(getTotalOrders(paging.total));
		} catch (e) {
			dispatch({
				type: GetOrdersTypes.GET_ORDERS_FAILURE,
			});
		}
	};

/**
 * Requisicao GET de um pedido especifico para o painel de datalhes
 * @param {string} code | Codigo de um pedido especifico
 */
export const getOrderDetail = (code: string) => async (dispatch: Dispatch) => {
	try {
		dispatch({
			type: GetOrdersTypes.GET_DETAIL_START,
		});

		const { data } = await getOrderByCode(code);

		dispatch({
			type: GetOrdersTypes.GET_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (e) {
		dispatch({
			type: GetOrdersTypes.GET_DETAIL_FAILURE,
		});
	}
};

/**
 * Requisicao GET de um pedido especifico para o painel de datalhes
 * @param {string} id | Codigo de um pedido especifico
 */
export const getSpecificOrder = (id: string) => async (dispatch: Dispatch) => {
	try {
		dispatch({
			type: GetOrdersTypes.GET_ORDERS_START,
		});
		const newData: OrderListData[] = [];

		const data = await getOrderById(id);

		newData.push(data);

		dispatch({
			type: GetOrdersTypes.GET_ORDERS_SUCCESS,
			payload: newData,
		});

		dispatch(getTotalOrders(1));
	} catch (e) {
		dispatch({
			type: GetOrdersTypes.GET_ORDERS_FAILURE,
		});
	}
};
