import styled from 'styled-components';
import { IconButton } from '@fluentui/react/lib/Button';

export const CustomButton = styled.button``;

export const Icon = styled(IconButton)`
	position: relative;
	top: 1px;
	margin-right: 15px;
	font-size: 20px;
	color: #333;
`;
