import styled from 'styled-components';
import { Toggle } from '@fluentui/react/lib/Toggle';

type Props = {
	smallInput?: boolean;
	hasItems?: boolean;
	offerId?: string;
};

export const TaxesContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 50px;
`;

export const InputsContainer = styled.div`
	margin: 10px 0;
	margin-top: 0;
	align-self: flex-end;
	display: flex;
	align-items: center;
	/* align-items: flex-end; */
	position: relative;
	gap: 10px;

	span {
		font-size: 14px;
		font-weight: 500;
	}
`;

export const DiscountInput = styled.div`
	width: 210px;
	position: relative;
`;

export const DiscountContainer = styled.div`
	position: relative;

	.ms-TextField {
		position: relative;
		top: -5px;
	}
`;

export const Switch = styled(Toggle)`
	position: absolute;
	top: 0;
	right: -7px;
`;

export const Percentage = styled.span`
	position: absolute;
	top: 30px;
	right: 12px;
	font-weight: bold;
`;
