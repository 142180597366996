import styled from 'styled-components';
import { Stack, Text } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

type Props = {
	smallInput?: boolean;
	showStep: boolean;
};

export const Container = styled(Stack)<Props>`
	display: ${({ showStep }) => (showStep ? 'flex' : 'none')};
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding-top: 70px;
	position: relative;
`;

export const Button = styled(DefaultButton)``;

export const FinishOrderBtn = styled(PrimaryButton)``;

export const StepControls = styled.div`
	display: flex;
	width: 95%;
	justify-content: space-between;
	border-top: solid 1px rgb(243, 242, 241);
	padding-top: 15px;
	padding: 25px;
`;

export const OrderTotal = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	margin-bottom: 15px;
`;

export const ItemValue = styled.div`
	display: flex;
	align-items: center;
`;

export const UnityPrice = styled(Text)`
	font-weight: 500;
	margin-left: 5px;
`;
