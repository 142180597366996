import { useState, useEffect } from 'react';

const useTimer = ({
	minutes,
	onTimerEnd,
}: {
	minutes: number;
	onTimerEnd: () => void;
}) => {
	const [seconds, setSeconds] = useState(minutes * 60);

	useEffect(() => {
		const duration = minutes * 60;
		let interval: any = null;

		if (seconds > 0) {
			interval = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);
		} else {
			onTimerEnd();
			setSeconds(minutes * 60);
		}

		return () => clearInterval(interval);
	}, [seconds, minutes, onTimerEnd]);

	return { seconds };
};

export default useTimer;
