import * as React from 'react';
import { CustomTitle } from './style';

interface ITitleProps {
	text: string;
}

const Title: React.FunctionComponent<ITitleProps> = ({ text }) => {
	return <CustomTitle>{text}</CustomTitle>;
};

export default Title;
