import styled from 'styled-components';
import { TextField, MaskedTextField, Spinner } from '@fluentui/react';

interface Props {
	hasError: boolean | undefined;
}

export const Field = styled(TextField)`
	text-decoration: none;
	/* .ms-TextField-fieldGroup {
    border: solid 1px #d3cecc;

    &:hover {
      border: solid 1px #5c5b5b;
    }
  } */
`;

export const MaskedInput = styled(MaskedTextField)``;

export const CurrencyField = styled.input`
	border: solid 1px #c5c1bf;
	height: 30px;
	padding: 0 8px 0 9px;
	border-radius: 2px;
	color: rgb(50, 49, 48);
	font-weight: 400;
	font-size: 14px;

	&:hover {
		border: solid 1px #706d6d;
	}

	&:focus {
		outline: none !important;
		border: solid 2px;
		border-color: ${(props) => props.theme.colors.primary};
		margin: -1px;
	}

	&::placeholder {
		color: rgb(50, 49, 48);
	}
`;

export const Label = styled.span`
	font-weight: 500;
`;

export const CurrencyContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	gap: 5px;
	position: relative;
`;

export const ErrorTxt = styled.span<Props>`
	position: absolute;
	opacity: ${({ hasError }) => (hasError ? '1' : '0')};
	bottom: ${({ hasError }) => (hasError ? '-20px' : '0')};
	transition: 0.23s ease;
	font-size: 12px;
	font-weight: 400;
	color: rgb(164, 38, 44);
`;

export const CustomSpinner = styled(Spinner)`
	position: absolute;
	right: 0;
	top: 35px;
`;

export const InputContainer = styled.div`
	position: relative;
`;
