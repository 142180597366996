import { Reducer } from 'redux';
import { EditOrderTypes } from './types';

const INITIAL_STATE = {
	loading: false,
	error: '',
	orderData: {},
};

export const addOrderItem: Reducer<typeof INITIAL_STATE> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case EditOrderTypes.ADD_ITEM_START:
			return {
				...state,
				loading: true,
			};
		case EditOrderTypes.ADD_ITEM_FAILURE:
			return {
				...state,
				loading: false,
				error: 'Ocorreu um erro ao processar sua requisição',
			};
		case EditOrderTypes.ADD_ITEM_SUCCESS:
			return {
				...state,
				loading: false,
				orderData: action.payload,
			};
		default:
			return state;
	}
};
