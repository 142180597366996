import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	padding-top: 5vh;
`;

export const Image = styled.img`
	width: 10%;
`;

export const SuccessText = styled.h2`
	font-size: 2vw;
`;

export const SubText = styled.p`
	font-size: 1.5vw;
`;

export const Back = styled(Link)`
	padding: 10px 30px;
	color: #717171;
	border-radius: 10px;
	border: 1px solid #fff;
	font-size: 1.5vw;

	transition: 0.3s;
	cursor: pointer;

	text-decoration: none;

	margin-top: 40px;
`;

export const PrintButton = styled.button`
	padding: 10px 30px;
	background-color: #d40638;
	color: #fff;
	border-radius: 10px;
	border: 1px solid #fff;
	font-size: 1.5vw;

	transition: 0.3s;
	cursor: pointer;

	text-decoration: none;

	margin-top: 40px;

	:hover {
		background-color: #c10230;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InfoContainer = styled.div`
	width: 40%;
`;

export const InfoHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	background-color: #eaedf0;
	margin-top: 7px;
	padding: 0 18px;
	border-radius: 4px 4px 0 0;
`;

export const BoldText = styled.p`
	font-weight: 500;
	font-size: 20px;
`;

export const InfoBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 3px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	min-height: 60px;
	border: solid 1px #e9e3e3;
	padding: 9px 18px;
`;

export const HorizontalContent = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: solid 1px #e9e3e3;
	padding-bottom: 20px;
	margin-top: 10px;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		padding-bottom: 10px;
		border: none;
	}
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
	overflow: hidden;
	flex-wrap: wrap;
	margin-top: 12px;

	p {
		margin: 0;
	}
`;

export const InfoTitle = styled.span`
	color: #776b6b;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
`;

export const InfoText = styled.strong`
	text-transform: uppercase;
	font-size: 18px;
`;
