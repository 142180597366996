import styled from 'styled-components';
import { Stack, Text, TextField, Separator } from '@fluentui/react';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

type Props = {
	smallInput?: boolean;
	hasItems?: boolean;
	offerId?: string;
};

export const Container = styled(Stack)<Props>`
	display: ${({ hasItems }) => (hasItems ? 'block' : 'none')};
	width: 100%;
	border-radius: 2px;
	border: solid 1px #c5c1bf;
	border-radius: 2px;
	padding: 8px;
	margin-bottom: 25px;
`;

export const Button = styled(DefaultButton)`
	position: absolute;
	left: 0;
`;

export const AddMoreItems = styled(DefaultButton)``;

export const ItemDescription = styled.div`
	display: flex;
	align-items: flex-start;
	max-width: 600px;
	width: 100%;
`;

export const ItemText = styled(Text)``;

export const ItemCode = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	min-width: 80px;
	padding-left: 10px;
	max-width: 80px;
`;

export const DescriptionContainer = styled.div`
	min-width: 380px;
	max-width: 380px;
`;

export const InfoHeader = styled.span`
	color: #776b6b;
	font-size: 12px;
`;

export const ItemQuantity = styled(Text)`
	margin-right: 4px;
	margin-left: 10px;
	font-weight: 500;
	background-color: #f5f3f3;
	font-size: 12px;
	border-radius: 6px;
	position: relative;
	top: -3px;
	padding: 4px 8px;
`;

export const UnityPrice = styled(Text)`
	font-weight: 500;
	max-width: 70px;
`;

export const Installments = styled(Text)``;

export const ItemValue = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 80px;
	width: 100%;
	position: relative;
	border-left: solid 1px #c5c1bf;
	min-height: 55px;
	height: 100%;
	padding-left: 20px;
`;

export const HorizontalFlex = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

export const DeleteButtonContainer = styled.div`
	border-left: solid 1px #c5c1bf;
	padding-left: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SectionTitle = styled(Text)`
	align-self: center;
	margin-bottom: 25px;
	margin-top: 25px;
`;

export const DeleteButton = styled(IconButton)`
	color: #f14e4e;

	i {
		font-size: 20px;
	}
`;

export const EditButton = styled(IconButton)`
	color: #fcc631dd;

	i {
		font-size: 20px;
	}

	:hover {
		color: #f9ca24;
	}
`;

export const ItemContainer = styled.div`
	width: 100%;
	padding: 12px 0;
	border-bottom: solid 1px #c5c1bf;
	position: relative;
	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: space-between;
	grid-gap: 48px;

	@media (max-width: 625px) {
		flex-direction: column;
	}

	&:first-child {
		padding-top: 6px;
	}
	&:last-child {
		margin-bottom: 0;
		padding-bottom: 6px;
		border-bottom: none;
	}
`;

export const ItemName = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	border-left: solid 1px #c5c1bf;
	border-right: solid 1px #c5c1bf;
	min-height: 55px;
	height: 100%;
	gap: 4px;
	position: relative;
`;

export const CreateOrderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const OrderInfo = styled.div<Props>`
	margin: 15px 0;
	margin-top: 5px;
	display: ${({ hasItems }) => (hasItems ? 'flex' : 'none')};
	margin-bottom: -18px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 15px;
`;

export const OrdersContainer = styled.div<Props>`
	display: ${({ hasItems }) => (hasItems ? 'flex' : 'none')};
	flex-direction: column;
	border: solid 1px #e6e6e4;
	background: #fff;
	box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 3%), 0 0.3px 0.9px 0 rgb(0 0 0 / 4%);
	border-radius: 3px;
	padding: 0 26px;
`;

export const InputsContainer = styled.div`
	margin: 15px 0;
	align-self: flex-end;
	display: flex;
	align-items: center;
	position: relative;
	gap: 10px;

	span {
		font-size: 14px;
		font-weight: 500;
	}
`;

export const OfferIdInput = styled(TextField)<Props>`
	position: relative;
	min-width: 285px;
	input[type='text'] {
		padding-left: 36px;
	}
	&::after {
		content: 'ID';
		position: absolute;
		top: 50%;
		color: #fff;
		transform: translateY(-50%);
		left: 8px;
		background: ${(props) => props.theme.colors.primary};
		border: solid 1px;
		border-color: ${(props) => props.theme.colors.primary};
		font-size: 12px;
		padding: 2px 4px;
		border-radius: 2px;
	}
`;

export const SeparatorLine = styled(Separator)`
	margin-bottom: 10px;
`;

export const Icon = styled(FontIcon)`
	font-size: 18px;
`;

export const TooltipContainer = styled.div`
	position: absolute;
	right: 6px;
	top: 8px;
`;

export const Tooltip = styled(TooltipHost)``;

export const TaxesOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: solid 1px #e6e6e4;
	border-radius: 3px;
	padding: 14px 26px;
	margin: 20px 0;
	padding-top: 20px;
	box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 3%), 0 0.3px 0.9px 0 rgb(0 0 0 / 4%);
	background: #fff;

	.ms-Button {
		min-width: 250px;
		position: relative;
		top: 4px;
	}
`;

export const ChangeQuantity = styled.div`
	display: flex;
	flex-direction: column;
	gap: 7px;
	max-width: 70px;
	padding-left: 25px;
	width: 100%;

	div {
		height: 29px;

		&::after {
			border-color: #c5c1bf;
		}
	}
`;

export const OrderTotal = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0;
	gap: 10px;
`;

export const TotalTitle = styled.span`
	font-size: 20px;
	font-weight: 500;
`;

export const TotalText = styled.p`
	font-size: 19px;
	position: relative;
	top: 1px;
`;
