import styled from 'styled-components';
import { FontIcon } from '@fluentui/react/lib/Icon';

type Props = {
	open?: boolean;
};

export const Container = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 3%;
	border-bottom: solid 1px rgb(243, 242, 241);
	position: relative;
`;

export const HeaderLogo = styled.img`
	max-width: 100px;
`;

export const Icon = styled(FontIcon)`
	cursor: pointer;
	position: relative;
	top: 1px;
	margin-right: 15px;
	font-size: 20px;
`;

export const UserContainer = styled.div``;

export const UserIcon = styled.button`
	cursor: pointer;
	border: solid 1px #696666;
	width: 40px;
	height: 40px;
	border-radius: 60%;
	background: transparent;
	font-size: 20px;
	transition: 0.1s ease;

	&:hover {
		background: ${(props) => props.theme.colors.primary};
		border-color: ${(props) => props.theme.colors.primary};
		color: #fff;
	}
`;

export const UserInfo = styled.div<Props>`
	position: absolute;
	right: 3%;
	top: 100%;
	width: 260px;
	padding: 18px;
	padding-top: 60px;
	background: #fff;
	border: solid 1px rgb(243, 242, 241);
	box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
	pointer-events: ${(props) => (props.open ? 'unset' : 'none')};
	opacity: ${(props) => (props.open ? '1' : '0')};
	transition: opacity 0.1s ease;
	z-index: 2;
`;

export const InfoIcon = styled.span`
	min-width: 55px;
	min-height: 55px;
	border: solid 1px;
	border-color: ${(props) => props.theme.colors.primary};
	background: ${(props) => props.theme.colors.primary};
	color: #fff;
	font-size: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 60%;
`;

export const InfoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
`;

export const InfoHeader = styled.img`
	position: absolute;
	top: 12px;
	left: 18px;
	max-width: 50px;
`;

export const User = styled.p`
	font-size: 18px;
`;

export const LogoutButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px 18px;
	background: transparent;
	border: solid 1px transparent;
	transition: 0.1s ease;
	font-size: 14px;
	color: #302929;

	&:hover {
		cursor: pointer;
		background: rgb(243, 242, 241);
		border-color: rgb(243, 242, 241);
	}
`;
