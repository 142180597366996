import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';

export const Container = styled(Stack)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 10px;
	padding: 28px;
	min-height: 350px;
	border-radius: 3px;
`;

export const ErrorText = styled.p`
	font-size: 18px;
	font-weight: 500;
	position: relative;
	left: -4px;
	color: #333;
	margin: 0;
`;

export const ErrorIcon = styled(FontIcon)`
	font-size: 50px;
`;
