import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
	useLocation,
} from 'react-router-dom';

import Autentication from '../utils/autentication';

import SuccessPage from './Payment/Success';
import LoagingPage from './loadingPage';
import CreateOrder from './admin/createOrder';
import Orders from './admin/orders';

import Navigation from '../containers/navigation';
import Header from '../containers/header';

export default function RootPage() {
	const location = useLocation();
	const navigate = useNavigate();

	const [isAutenticated, setIsAuthenticated] = useState(true);
	const [homeAccount, setHomeAccount] = useState({});
	let theme = 'roval';

	if (location.search.includes('order_id')) {
		return <SuccessPage />;
	}

	return (
		<Autentication
			nonAuthenticated={<LoagingPage theme={theme} />}
			isAutenticated={isAutenticated}
			setIsAuthenticated={setIsAuthenticated}
			setHomeAccount={setHomeAccount}
		>
			<>
				<Header theme={theme} homeAccount={homeAccount} />
				<Navigation />
				<Routes>
					<Route path="/" element={<CreateOrder theme={theme} />} />
					<Route path="/pedidos" element={<Orders />} />
					<Route path="/pedidos/success" element={<SuccessPage />} />
					{/* <Route path="/pedidos/print" element={<PrintOrder />} /> */}
					{/* <Route path="/pagamentos" element={<Payments />} />
      <Route path="/relatorio" element={<Report />} /> */}
				</Routes>
			</>
		</Autentication>
	);
}
