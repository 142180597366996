import styled from 'styled-components';
import { Panel } from '@fluentui/react/lib/Panel';

interface Props {
	lastStep?: boolean;
	step?: number;
	orderBox?: boolean;
	noMargin?: boolean;
}

interface ItemName {
	itemName?: boolean;
	itemCode?: boolean;
}

export const Container = styled.div``;

export const DetailsPanel = styled(Panel)``;

export const HeaderButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	margin-top: 30px;
	border-radius: 4px 4px 0 0;
`;

export const InfoHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	background-color: #eaedf0;
	margin-top: 7px;
	padding: 0 18px;
	border-radius: 4px 4px 0 0;
`;

export const BoldText = styled.p<Props>`
	font-weight: 500;
	font-size: 16px;
	margin: ${({ noMargin }) => noMargin && '0'};
`;

export const InfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 7px;
	margin-bottom: 30px;
	gap: 10px;
`;

export const InfoBox = styled.div<Props>`
	display: flex;
	flex-direction: column;
	justify-content: ${({ orderBox }) => (orderBox ? 'space-between' : 'unset')};
	border-radius: 3px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	min-height: 60px;
	border: solid 1px #e9e3e3;
	width: 100%;
	padding: 9px 18px;
`;

export const InfoTitle = styled.span`
	color: #776b6b;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 500;
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
	overflow: hidden;
	flex-wrap: wrap;
	margin-top: 12px;

	p {
		margin: 0;
	}
`;

export const Text = styled.p<ItemName>`
	min-width: ${({ itemName, itemCode }) => {
		if (itemName) return '250px';
		if (itemCode) return '50px';
		return 'unset';
	}};
	max-width: ${({ itemName, itemCode }) => {
		if (itemName) return '250px';
		if (itemCode) return '50px';
		return 'unset';
	}};
`;

export const ChargeContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const BoxTitle = styled.span`
	color: #776b6b;
`;

export const HorizontalHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: solid 1px #e9e3e3;
	margin-top: -10px;
`;

export const HorizontalContent = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: solid 1px #e9e3e3;
	padding-bottom: 20px;
	margin-top: 10px;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		padding-bottom: 10px;
		border: none;
	}
`;

export const HeaderCode = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const HeaderInfoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

export const DeliveryValue = styled.p`
	position: relative;
	top: 1px;
`;

export const TaxesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 25px;
`;
