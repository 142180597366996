import { EmbeddedReport } from '../../../pages/admin/report/types';

/**
 * Action types
 */
export const GetEmbeddedTypes = {
	GET_EMBEDDED_START: 'GET_EMBEDDED_START',
	GET_EMBEDDED_SUCCESS: 'GET_EMBEDDED_SUCCESS',
	GET_EMBEDDED_FAILURE: 'GET_EMBEDDED_FAILURE',
};

/**
 * State type
 */
export interface GetEmbeddedState {
	readonly embeddedInfo: EmbeddedReport | undefined;
}
