import { Reducer } from 'redux';
import { GetOrdersTypes, GetOrdersState } from './types';

const INITIAL_STATE: GetOrdersState = {
	orders: [],
	orderDetail: [],
	totalOrders: 1,
	loadingList: false,
	loadingDetails: false,
	status: '',
	code: '',
	createdSince: '',
	createdUntil: '',
};

/**
 * GET request para criar o pedido
 */
export const getOrdersList: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.GET_ORDERS_START:
			return {
				...state,
				loadingList: true,
			};
		case GetOrdersTypes.GET_ORDERS_SUCCESS:
			return {
				...state,
				orders: action.payload,
				loadingList: false,
			};
		case GetOrdersTypes.GET_ORDERS_FAILURE:
			return {
				...state,
				loadingList: false,
			};
		default:
			return state;
	}
};

/**
 * GET request para criar o pedido
 */
export const getOrderDetail: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.GET_DETAIL_START:
			return {
				...state,
				loadingDetails: true,
			};
		case GetOrdersTypes.GET_DETAIL_SUCCESS:
			return {
				...state,
				orderDetail: action.payload,
				loadingDetails: false,
			};
		case GetOrdersTypes.GET_ORDERS_FAILURE:
			return {
				...state,
				loadingDetails: false,
			};
		default:
			return state;
	}
};

/**
 * Total de pedidos
 */
export const getTotalOrders: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.SET_TOTAL_ORDERS:
			return {
				...state,
				totalOrders: action.payload,
			};
		default:
			return state;
	}
};

/**
 * Status da lista de pedidos
 */
export const setOrderStatus: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case GetOrdersTypes.CLEAR_STATUS_FILTER:
			return {
				...state,
				status: '',
			};
		default:
			return state;
	}
};

/**
 * Codigo do pedido
 */
export const setOrderCode: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.SET_ORDER_CODE:
			return {
				...state,
				code: action.payload,
			};
		case GetOrdersTypes.CLEAR_ORDER_CODE:
			return {
				...state,
				code: '',
			};
		default:
			return state;
	}
};

/**
 * Filtro de datas
 */
export const setOrderDates: Reducer<GetOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetOrdersTypes.SET_CREATED_SINCE:
			return {
				...state,
				createdSince: action.payload,
			};
		case GetOrdersTypes.SET_CREATED_UNTIL:
			return {
				...state,
				createdUntil: action.payload,
			};
		case GetOrdersTypes.CLEAR_CREATED_SINCE:
			return {
				...state,
				createdSince: '',
			};
		case GetOrdersTypes.CLEAR_CREATED_UNTIL:
			return {
				...state,
				createdUntil: '',
			};
		default:
			return state;
	}
};
