import styled from 'styled-components';
import { PrimaryButton, Spinner, Icon } from '@fluentui/react';

interface Props {
	isLoading?: boolean;
	orderBtn?: boolean;
}

export const CustomButton = styled(PrimaryButton)<Props>`
	position: relative;
`;

export const ButtonText = styled.span<Props>`
	margin: 0px 4px;
	line-height: 100%;
	display: block;
	font-weight: 600;
	opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
`;

export const CustomSpinner = styled(Spinner)`
	position: absolute;
	width: 100%;
	background-color: #f7f1f1;
	inset: 0;
`;

export const CheckIcon = styled(Icon)`
	color: green;
	font-size: 25px;
	position: absolute;
	width: 100%;
`;
