import styled from 'styled-components';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-bottom: solid 1px rgb(243, 242, 241);

	@media (max-width: 585px) {
		display: none;
	}
`;

export const Nav = styled.nav`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1017px;
	width: 100%;
	min-height: 100px;

	.active {
		border-bottom: solid 2px;
		border-color: ${(props) => props.theme.colors.primary};
	}
`;

export const Icon = styled(FontIcon)`
	position: relative;
	top: 1px;
	margin-right: 15px;
	font-size: 20px;
`;

export const CustomLink = styled(NavLink)`
	text-decoration: none;
	min-height: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #333;
	font-size: 20px;
	border-bottom: solid 2px transparent;

	&:hover {
		background: #f0f1f3;
	}
`;
