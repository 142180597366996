import styled from 'styled-components';
import { Stack, Text, PrimaryButton, DefaultButton } from '@fluentui/react';

export const Container = styled(Stack)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	align-items: center;
	background: #fff;
	/* max-width: 1920px; */
	width: 100%;
	border: solid 1px #fff;
	border-radius: 3px;
	margin: 0 auto;
	padding-bottom: 24px;
	box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 6%), 0 0.3px 0.9px 0 rgb(0 0 0 / 8%);
`;

export const BoldText = styled(Text)``;

export const ListHeader = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	margin-bottom: 20px;
	border-bottom: solid 1px rgb(243, 242, 241);
	border-radius: 3px;
	padding: 12px 0;
	padding-top: 0;
	min-height: 100px;
	width: 100%;
`;

export const Title = styled.div`
	align-self: center;
	text-align: center;
	padding: 12px;
	margin-bottom: -20px;
	background-color: #eaedf0;
	width: 100%;
`;

export const TitleText = styled.span`
	font-size: 23px;
	font-weight: 500;
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: flex-end;
	max-width: 700px;
	width: 100%;
	gap: 10px;
`;

export const Input = styled.div`
	max-width: 500px;
	width: 100%;
	width: 100%;
`;

export const ListContainer = styled.div`
	width: 100%;
`;

export const AlignInstallments = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-left: -10px;
`;

export const FilterButton = styled(PrimaryButton)``;

export const FilterActive = styled.span`
	background: #eaedf0;
	padding: 4px 4px 4px 12px;
	border-radius: 24px;
	color: #3d3f41c6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
`;

export const FiltersContainer = styled.span`
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-right: 10px;
	gap: 15px;
`;

export const ResetBtn = styled.button`
	width: 5px;
	border: none;
	color: #3d3f41c6;
	margin-left: 10px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

export const Clear = styled(DefaultButton)`
	font-size: 12px;
	padding: 8px;
	border-color: #e6e0dd;
	height: 26px;
`;
