import React, { useState } from 'react';
import { IIconProps } from '@fluentui/react';
import { Icon } from './style';

interface ICopyButtonProps {
	copyText: string;
}

const copyIcon: IIconProps = { iconName: 'Copy' };
const checkMark: IIconProps = { iconName: 'CheckMark' };

const CopyButton: React.FunctionComponent<ICopyButtonProps> = ({
	copyText,
}) => {
	const [isCopied, setIsCopied] = useState(false);

	const copyTextToClipboard = async (text: string) => {
		if ('clipboard' in navigator) {
			await navigator.clipboard.writeText(text);
			return;
		}
		return document.execCommand('copy', true, text);
	};

	const handleCopyClick = () => {
		copyTextToClipboard(copyText)
			.then(() => {
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch((error) => {
				setIsCopied(false);
				console.log(error);
			});
	};

	return (
		<Icon
			iconProps={isCopied ? checkMark : copyIcon}
			title="Delete"
			ariaLabel="Delete"
			onClick={() => handleCopyClick()}
		/>
	);
};

export default CopyButton;
