import styled from 'styled-components';
import { Stack, DefaultButton, Spinner } from '@fluentui/react';

type Props = {
	smallInput?: boolean;
	showStep?: boolean;
	loading?: boolean;
};

export const Container = styled(Stack)<Props>`
	display: ${({ showStep }) => (showStep ? 'flex' : 'none')};
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding-top: 70px;
`;

export const InputContainer = styled.div<Props>`
	width: ${({ smallInput }) => (smallInput ? '55%' : '100%')};
`;

export const CpfInput = styled(InputContainer)`
	position: relative;
`;

export const CustomSpinner = styled(Spinner)<Props>`
	opacity: ${({ loading }) => (loading ? '1' : '0')};
	position: absolute;
	transition: opacity 0.1s ease;
	right: 12px;
	top: 37px;
`;

export const Form = styled.div`
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width: 95%;
	gap: 10px;
	justify-content: space-between;
	padding-top: 25px;
	border: solid 1px #e6e6e4;
	border-radius: 3px;

	padding: 25px;

	@media (max-width: 565px) {
		flex-direction: column;
		padding-top: 25px;
	}
`;

export const HorizontalFlex = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;
	justify-content: space-between;
	margin-top: 25px;

	@media (max-width: 565px) {
		flex-direction: column;
		margin-top: 0px;
	}
`;

export const StepControls = styled.div`
	display: flex;
	width: 95%;
	justify-content: space-between;
	border-top: solid 1px rgb(243, 242, 241);
	padding-top: 15px;
	margin-top: 20px;
`;

export const FormInfoText = styled.div`
	margin-top: -5px;
`;

export const Button = styled(DefaultButton)``;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
