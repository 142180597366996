import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

import config from '../../../config.json';

import ClientInfo from './containers/clientInfo';
import OrderInfo from './containers/orderInfo';
import PaymentStep from './containers/paymentInfo';
import StepsHeader from './components/stepper';
import PaymentResume from './containers/paymentResume';
import PaymentLink from './containers/paymentLink';
import { DataState } from '../../../common/redux/rootReducer';
import { FormErrors, Item, ItemsBody, PaymentType } from './types';
import { setOrderCode } from '../../../common/redux/orderList/actions';
import {
	// handleResetForms,
	postBudgetItems,
	getClientWithCPF,
	handleResetBudget,
	handleClientData,
	handleResetClient,
} from '../../../common/redux/createOrder/actions';
import {
	budgeItemsBody,
	clientBody,
	resaleItemsBody,
} from '../../../common/utils/formatted';
import { Container, Content, Stepper, BudgetId, BudgetText } from './style';
import Modal from './components/modal';

interface ICreateOrderProps {
	theme?: string;
}

const CreateOrder: React.FunctionComponent<ICreateOrderProps> = ({ theme }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	if (location.search.includes('order_id')) {
		navigate(
			`/pedidos/success?orderId=${location.search.split('order_id=')[1]}`
		);

		return <></>;
	}

	const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
		useBoolean(false);
	const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
	const [items, setItems] = useState<ItemsBody[]>([]);
	const [paymentType, setPaymentType] = useState<PaymentType[] | object>({
		expiresAt: moment()
			.add(config?.payment?.expireDayCount || 2, 'days')
			.format('DD/MM/YYYY'),
	});
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [budgetItemId, setBudgetItemId] = useState<string | undefined>('');
	const [clientCpf, setClientCpf] = useState<string | undefined>('');
	const [clientModal, setClientModal] = useState(false);
	const [isResale, setIsResale] = useState<boolean | undefined>(false);
	const [offerId, setOfferId] = useState('');
	const [step, setStep] = useState<number | undefined>(0);
	const hasId = offerId && step !== 4;
	const {
		clientData,
		orderItems,
		payments,
		total,
		orderResults,
		orderBudget,
		shipping,
		discount,
		clientBudget,
	} = useSelector((state: DataState) => state);

	const handleStep = (value: number) => {
		setStep(value);
	};

	const handleBudgetItemId = (value: string) => {
		setBudgetItemId(value);
	};

	const handleClientCpf = (value: string) => {
		const valueClean = value.replace(/[,.-]/g, '');
		setClientCpf(valueClean);
	};

	const handleReset = (step: number | undefined) => {
		// dispatch(handleResetForms());
		dispatch(handleClientData({}));
		setPaymentMethods([]);
		setItems([]);
		setPaymentType([]);
		setOfferId('');
		setStep(step);
	};

	const getBudgetItems = async () => {
		await dispatch<any>(
			postBudgetItems(
				budgeItemsBody(budgetItemId),
				setFormErrors,
				resaleItemsBody(budgetItemId),
				isResale
			)
		);
	};

	const getClients = async () => {
		await dispatch<any>(getClientWithCPF(clientBody(clientCpf), setFormErrors));
	};

	const redirectToOrder = async () => {
		if (orderResults.orderData?.id) {
			dispatch(setOrderCode(orderResults.orderData?.id));
			navigate('/pedidos');
			handleReset(0);
		}
	};

	const checkExistItem = (itemId: string): boolean => {
		if (items.length === 0) return false;
		let alreadyAdded = false;

		items.forEach((item: any) => {
			if (item.code === itemId) alreadyAdded = true;
		});

		return alreadyAdded;
	};

	const addOrderItem = (itemsInfo: any) => {
		let newAmount = 0;
		let newItemId = itemsInfo.CODIGO.toString();
		let itemBody: ItemsBody = {};

		if (isResale) {
			const parsedValue = parseFloat(itemsInfo.PRECO).toFixed(2);
			newAmount = parseInt(parsedValue.toString().replace(/[,.]/g, ''));
		} else {
			newAmount = parseInt(itemsInfo.PRECO.replace(/[,.]/g, ''));
		}

		itemBody = {
			description: `${itemsInfo.NOME}`,
			quantity: itemsInfo.QUANTIDADE ?? 1,
			code: newItemId,
			amount:
				itemsInfo.QUANTIDADE && itemsInfo.QUANTIDADE > 1
					? newAmount / itemsInfo.QUANTIDADE
					: newAmount,
		};

		let budgetItems: Item[] = [];

		if (checkExistItem(newItemId)) {
			budgetItems = items.map((item: any) => {
				if (newItemId === item.code) {
					return {
						...item,
						quantity: (item.quantity += 1),
					};
				}
				return item;
			});
		} else {
			budgetItems.push(...items, itemBody);
		}

		setItems(budgetItems);
	};

	const openClientModal = () => {
		setClientModal(true);
		showModal();
	};

	const exitModal = () => {
		setClientModal(false);
		hideModal();
		if (orderBudget.budget) {
			dispatch(handleResetBudget());
		}
		if (clientBudget.clientBudgetData) {
			dispatch(handleResetClient());
		}
	};

	const modalTitle = () => {
		if (clientModal) return 'Qual cliente você gostaria de importar?';
		return 'Qual produto você gostaria de importar?';
	};

	const handleResale = (value: boolean | undefined) => {
		if (orderBudget.budget) {
			dispatch(handleResetBudget());
		}
		setIsResale(value);
	};

	return (
		<Container>
			<Modal
				title={modalTitle()}
				isModalOpen={isModalOpen}
				hideModal={exitModal}
				clientModal={clientModal}
				getClientBudgetData={getClients}
				loadingClient={clientBudget.loadingClient}
				clientData={clientBudget.clientBudgetData}
				isResale={isResale}
				formErrors={formErrors}
				loading={orderBudget.loadingBudget}
				items={items}
				handleBudgetItemId={handleBudgetItemId}
				clientInfo={clientData.clientInfo}
				handleClientCpf={handleClientCpf}
				getBudgetItems={getBudgetItems}
				budgetItems={orderBudget.budget}
				setIsResale={handleResale}
				addOrderItem={addOrderItem}
				setFormErrors={setFormErrors}
			/>
			<Content>
				{hasId && (
					<BudgetId>
						<BudgetText>ID: {offerId}</BudgetText>
					</BudgetId>
				)}
				<Stepper lastStep={step === 4}>
					<StepsHeader handleStep={handleStep} steps={step} theme={theme} />
				</Stepper>
				<ClientInfo
					step={step}
					formErrors={formErrors}
					clientInfo={clientData.clientInfo}
					handleStep={handleStep}
					showModal={openClientModal}
					loading={clientBudget.loadingClient}
					setFormErrors={setFormErrors}
				/>
				<OrderInfo
					step={step}
					formErrors={formErrors}
					items={items}
					shippingPrice={shipping.shippingPrice}
					discountPrice={discount.discountPrice}
					offerId={offerId}
					setItems={setItems}
					addOrderItem={addOrderItem}
					showModal={showModal}
					setOfferId={setOfferId}
					handleStep={handleStep}
					setFormErrors={setFormErrors}
				/>
				<PaymentStep
					step={step}
					paymentType={paymentType}
					paymentMethods={paymentMethods}
					formErrors={formErrors}
					orderTotal={total.orderTotal}
					handleStep={handleStep}
					setFormErrors={setFormErrors}
					setPaymentType={setPaymentType}
					setPaymentMethods={setPaymentMethods}
				/>
				<PaymentResume
					step={step}
					offerId={offerId}
					paymentType={payments.paymentTypes}
					paymentMethods={paymentMethods}
					clientInfo={clientData.clientInfo}
					orderTotal={total.orderTotal}
					formErrors={formErrors}
					items={orderItems.items}
					shippingPrice={shipping.shippingPrice}
					loading={orderResults.loadingOrder}
					setFormErrors={setFormErrors}
					discountPrice={discount.discountPrice}
					handleStep={handleStep}
				/>
				<PaymentLink
					step={step}
					total={total.orderTotal}
					orderData={orderResults.orderData}
					handleStep={handleReset}
					redirectToOrder={redirectToOrder}
				/>
			</Content>
		</Container>
	);
};

export default CreateOrder;
