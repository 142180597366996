import { maskCurrency } from './masks';

/**
 * Formatar para moeda brasileira
 */
export const currencyMask = (value: any) => {
	const onlyDigits = value
		.split('')
		.filter((s: any) => /\d/.test(s))
		.join('')
		.padStart(3, '0');
	const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
	return maskCurrency(digitsFloat);
};

/**
 * Formatar para moeda brasileira
 * Pagarme somente aceita valores inteiros
 */
export const formatPrice = (value: any, quantity?: number) => {
	if (value === null || value === undefined) return null;
	let totalValue = quantity ? value * quantity : value * 1;
	return currencyMask(totalValue.toString());
};
