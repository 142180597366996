import { OrderListData } from '../../../pages/admin/orders/types/index';

/**
 * Action types
 */
export const GetOrdersTypes = {
	GET_ORDERS_START: 'GET_ORDERS_START',
	GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
	GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
	GET_DETAIL_START: 'GET_DETAIL_START',
	GET_DETAIL_SUCCESS: 'GET_DETAIL_SUCCESS',
	GET_DETAIL_FAILURE: 'GET_DETAIL_FAILURE',
	SET_TOTAL_ORDERS: 'SET_TOTAL_ORDERS',
	SET_STATUS: 'SET_STATUS',
	SET_ORDER_CODE: 'SET_ORDER_CODE',
	SET_CREATED_SINCE: 'SET_CREATED_SINCE',
	SET_CREATED_UNTIL: 'SET_CREATED_UNTIL',
	CLEAR_STATUS_FILTER: 'CLEAR_STATUS_FILTER',
	CLEAR_CREATED_SINCE: 'CLEAR_CREATED_SINCE',
	CLEAR_CREATED_UNTIL: 'CLEAR_CREATED_UNTIL',
	CLEAR_ORDER_CODE: 'CLEAR_ORDER_CODE',
};

/**
 * State type
 */
export interface GetOrdersState {
	readonly orders: OrderListData[];
	readonly orderDetail: OrderListData[];
	readonly loadingList: boolean;
	readonly loadingDetails: boolean;
	readonly totalOrders: number;
	readonly status: string;
	readonly code: string;
	readonly createdSince: Date | '';
	readonly createdUntil: Date | '';
}
