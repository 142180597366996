import styled from 'styled-components';
import { Modal, Toggle } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import Button from '../../../../../common/ui/button';

type Props = {
	smallInput?: boolean;
	hasItems?: boolean;
	offerId?: string;
};

export const ModalContainer = styled(Modal)<Props>`
	.ms-Modal-scrollableContent {
		min-width: 900px;
		min-height: 300px;
		width: 100%;
	}
	.ms-Dialog-main {
		overflow-y: hidden;
		overflow-x: hidden;
	}
`;

export const ModalHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const SeparatorLine = styled(Separator)`
	margin-top: -15px;
`;

export const ModalContent = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const Switch = styled(Toggle)`
	position: absolute;
	top: 4px;
	right: -7px;
`;

export const HorizontalFlex = styled.div`
	display: flex;
	max-width: 600px;
	width: 100%;
	gap: 10px;
	justify-content: center;
	align-items: flex-start;
	margin-top: 45px;
	margin-bottom: 40px;

	@media (max-width: 565px) {
		flex-direction: column;
		margin-top: 0px;
	}
`;

export const InputContainer = styled.div<Props>`
	position: relative;
	width: ${({ smallInput }) => (smallInput ? '55%' : '100%')};
`;

export const ButtonContainer = styled.div`
	margin-top: 29px;
`;

export const ModalItems = styled.div`
	margin-bottom: 30px;
	padding-top: 10px;
`;

export const ItemHeader = styled.span`
	text-transform: uppercase;
	color: #776b6b;
	font-size: 12px;
`;

export const ItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 24px;
	border-bottom: solid 1px #dad5d2;

	&:first-child {
		border-top: solid 1px #dad5d2;
		padding-top: 16px;
	}
	&:last-child {
		margin-bottom: 0;
		padding-bottom: 6px;
	}
`;

export const ItemDescription = styled.div`
	min-width: 330px;
	max-width: 330px;
`;

export const Text = styled.p`
	margin-top: 10px;
`;

export const ItemCode = styled.div`
	min-width: 80px;
	max-width: 80px;
`;

export const ItemPrice = styled.div`
	min-width: 100px;
	max-width: 100px;

	p {
		font-weight: 500;
	}
`;

export const Quantity = styled.div`
	min-width: 100px;
	max-width: 100px;
`;

export const ClientDocument = styled.div`
	min-width: 80px;
	max-width: 80px;
`;

export const ClientName = styled.div`
	min-width: 250px;
	max-width: 300px;
`;

export const ClientInfo = styled.div``;

export const ChangeQuantity = styled.div`
	max-width: 70px;
	width: 100%;

	div {
		height: 29px;

		&::after {
			border-color: #c5c1bf;
		}
	}
`;
