// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: #f4f7f9;
    overflow-x: hidden;
  }

  input {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  .ms-DetailsHeader-cellTitle {
    padding-left: 28px;
  }

  .ms-TextField-fieldGroup {
    border: solid 1px #c5c1bf;

    &:hover {
      border: solid 1px #706d6d;
    }
  }

  .ms-ComboBox {
    &::after {
      border: solid 1px #d3cecc;
    }
  }

  .sc-iqcoie {
    div {
      &::after {
        border: solid 1px #d3cecc;
  
        
        &:hover {
          border: solid 1px #5c5b5b;
        }
      }
    }
  }
`;

export default GlobalStyle;
