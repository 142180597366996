export const maskCurrency = (
	valor: any,
	locale = 'pt-BR',
	currency = 'BRL'
) => {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
	}).format(valor);
};

/**
 * Formatar para moeda brasileira
 */
export const currencyMask = (event: any) => {
	const onlyDigits = event.target.value
		.split('')
		.filter((s: any) => /\d/.test(s))
		.join('')
		.padStart(3, '0');
	const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
	event.target.value = maskCurrency(digitsFloat);
};

/**
 * Formatar para moeda brasileira
 */
export const currency = (e: React.FormEvent<HTMLInputElement>) => {
	let { value } = e.currentTarget;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

	e.currentTarget.value = value;
	return e;
};

/**
 * Esconder primeiras letras do email
 */
export const hiddenEmail = (email: string) => {
	return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

/**
 * Mascara de cpf
 */
export const cpfMask = (cpf: string) => {
	if (cpf) {
		cpf = cpf.replace(/\D/g, '');
		cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
		cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
		cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
		return cpf;
	}
};

/**
 * Mostrar somente os quatro ultimos digitos do cartao de credito
 */
export const hiddenCreditCard = (cc: string) => {
	const newCreditCard = `***${cc.slice(-4)}`;
	return newCreditCard;
};

/**
 * Esconder numeros do meio do cpf
 */
export const hiddenCpf = (cpf: string) => {
	const formattedCpf = cpfMask(cpf);
	const firstDigits = formattedCpf?.substring(0, 4);
	const lastDigits = formattedCpf?.slice(-3);
	const newCpf = `${firstDigits}xxx.xxx${lastDigits}`;
	return newCpf;
};
