import React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, Text } from '@fluentui/react';
import SpinButton from '../../../../../common/ui/spinButton';
import { Item, FormErrors, ItemInfo } from '../../types/index';
import { validItemsInfo } from '../../../../../common/utils/validations';
import Input from '../../../../../common/ui/input';
import {
	InputContainer,
	Form,
	HorizontalFlex,
	ProductForm,
	AddButton,
	ErrorMessage,
	SectionTitle,
	AddMoreItems,
	Buttons,
	TitleContainer,
} from './style';
import { formatPrice } from '../../../../../common/utils/formatPrice';

interface IOrderFormProps {
	itemsInfo: ItemInfo;
	items: Item[];
	formErrors: FormErrors;
	sameItem: boolean;
	setItemsInfo: (newState: ItemInfo) => void;
	setSameItem: (newState: boolean) => void;
	addOrderItem: (newState: Item[], setSameItem: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	showModal: () => void;
	editOrder?: {
		edit: boolean;
		loading: boolean;
	};
}

const OrderInfoForm: React.FunctionComponent<IOrderFormProps> = ({
	setItemsInfo,
	sameItem,
	items,
	formErrors,
	itemsInfo,
	addOrderItem,
	setSameItem,
	showModal,
	setFormErrors,
	editOrder,
}) => {
	const handleItems = (fieldName: string, value: any) => {
		console.log(value);
		setItemsInfo({
			...itemsInfo,
			[fieldName]: value,
		});
	};

	let data = {
		description: '',
		code: '',
		quantity: 1,
		amount: 0,
		id: '',
	};

	const checkItemIsValid = () => {
		if (validItemsInfo(itemsInfo, setFormErrors)) {
			addOrderItem([itemsInfo], setSameItem);

			setItemsInfo(data);
		}
	};

	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	return (
		<Form>
			<TitleContainer>
				<SectionTitle variant="xLarge">
					{items.length <= 0
						? 'Importar ou inserir itens do pedido'
						: 'Insira os itens manualmente'}
				</SectionTitle>
				{items.length <= 0 && (
					<AddMoreItems text="Importar item" onClick={showModal} />
				)}
			</TitleContainer>
			<ProductForm>
				<HorizontalFlex>
					<InputContainer>
						<Input
							label="Nome do Produto (*)"
							value={itemsInfo.description ?? ''}
							error={formErrors.description}
							errorMessage="Informe o nome do produto"
							onFocus={() => clearFormErrors('description')}
							onChange={(e, value) => handleItems('description', value)}
						/>
					</InputContainer>
					<InputContainer smallInput>
						<Input
							label="Código do Produto (*)"
							error={formErrors.code}
							value={itemsInfo.code ?? ''}
							errorMessage="Informe o código do produto"
							onFocus={() => clearFormErrors('code')}
							onChange={(e, value) => handleItems('code', value)}
						/>
					</InputContainer>
				</HorizontalFlex>
				<HorizontalFlex>
					<InputContainer>
						<SpinButton
							label="Quantidade (*)"
							minValue={1}
							maxValue={100}
							onFocus={() => clearFormErrors('quantity')}
							errorMessage="Quantidade inválida"
							value={itemsInfo?.quantity?.toString() ?? ''}
							error={formErrors.quantity}
							onChange={(e, value) => handleItems('quantity', value)}
						/>
					</InputContainer>
					<InputContainer>
						<Input
							label="Valor unitário (*)"
							error={formErrors.amount}
							onFocus={() => clearFormErrors('amount')}
							value={formatPrice(itemsInfo?.amount?.toString()) ?? ''}
							defaultValue={formatPrice(itemsInfo?.amount?.toString()) ?? ''}
							maskType="currency"
							errorMessage="Informe o valor"
							onChange={(e) => {
								console.log(e.target.value);
								handleItems(
									'amount',
									parseInt(
										e.target.value
											.toString()
											.replace('R$', '')
											.replace(/[,.-]/g, '')
									)
								);
							}}
						/>
					</InputContainer>
				</HorizontalFlex>
				<Buttons>
					<AddMoreItems text="Limpar" onClick={() => setItemsInfo(data)} />
					<AddButton>
						{editOrder?.loading ? (
							<Spinner />
						) : (
							<PrimaryButton
								text="Adicionar"
								onClick={() => checkItemIsValid()}
							/>
						)}
					</AddButton>
				</Buttons>
				<ErrorMessage hasError={sameItem}>
					Erro: Um item com o mesmo ID já foi adicionado.
				</ErrorMessage>
			</ProductForm>
		</Form>
	);
};

export default OrderInfoForm;
