import React, { useState, useEffect } from 'react';
import { useId } from '@fluentui/react-hooks';
import { IIconProps, IconButton } from '@fluentui/react';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { useDispatch } from 'react-redux';
import Title from '../../../../../common/ui/title';
import Input from '../../../../../common/ui/input';
import Button from '../../../../../common/ui/button';
import { Client, FormErrors } from '../../types';
import {
	ModalContainer,
	ModalHeader,
	ModalContent,
	HorizontalFlex,
	InputContainer,
	Switch,
	ButtonContainer,
	ModalItems,
	ItemContainer,
	ItemDescription,
	Text,
	ItemPrice,
	ItemCode,
	ItemHeader,
	Quantity,
	ClientDocument,
	ClientName,
	ClientInfo,
	ChangeQuantity,
} from './style';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import { handleClientData } from '../../../../../common/redux/createOrder/actions';

interface IModalProps {
	title: string;
	isModalOpen: boolean;
	formErrors: FormErrors;
	isResale: boolean | undefined;
	loading: boolean;
	items: any;
	budgetItems: any;
	clientModal: boolean;
	loadingClient: boolean;
	clientInfo: Client;
	clientData: any;
	hideModal: () => void;
	addOrderItem: (itemsInfo: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	getClientBudgetData: () => void;
	getBudgetItems: () => void;
	setIsResale: (newState: boolean | undefined) => void;
	handleBudgetItemId: (value: string) => void;
	handleClientCpf: (value: string) => void;
}
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const Modal: React.FunctionComponent<IModalProps> = ({
	title,
	isModalOpen,
	formErrors,
	loading,
	isResale,
	budgetItems,
	clientData,
	loadingClient,
	clientInfo,
	clientModal,
	items,
	setFormErrors,
	getBudgetItems,
	handleBudgetItemId,
	handleClientCpf,
	getClientBudgetData,
	addOrderItem,
	setIsResale,
	hideModal,
}) => {
	const dispatch = useDispatch();

	const titleId = useId(title);
	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	const handleItemAdded = (id: number): boolean => {
		let isAdded = false;
		isAdded = items.some((item: any) => item.code === id.toString());
		return isAdded;
	};

	const clientIsAdded = (id: string): boolean => {
		let isAdded = false;
		if (clientInfo.cpf === id) {
			isAdded = true;
		}
		return isAdded;
	};

	const handlePrice = (value: any) => {
		const parsedValue = parseFloat(value).toFixed(2);
		const newValue = parseInt(parsedValue.toString().replace(/[,.]/g, ''));
		return newValue;
	};

	const setClientData = (client: any) => {
		let clientBody: Client = {};
		const clientDDD = client.DDD?.replace(/\s/g, '');
		const clientNumber = client.CELULAR
			? client.CELULAR?.replace(/\s/g, '')
			: client.TELEFONE?.replace(/\s/g, '');

		clientBody = {
			name: client.NOME ?? '',
			email: client.EMAIL ?? '',
			cpf: client.CPF_CNPJ ?? '',
			phoneNumber:
				client.DDD && clientNumber ? `${clientDDD}${clientNumber}` : '',
		};

		dispatch(handleClientData(clientBody));
		hideModal();
	};

	// const changeBudgetItemsQuantity = (value: any) => {
	//   let newProducts: any = [...budgetItems?.output];
	//   const productSelected = budgetItems?.output?.filter((item: any) => item.CDPRO === itemId);

	//   let selectedElement = { ...productSelected };
	//   selectedElement[0].QTFOR = parseInt(value);

	//   budgetItems?.output?.filter((item: any) => {
	//     if (item.Id === itemId) {
	//       item = selectedElement;
	//     }
	//   });

	//   console.log(newProducts);
	// }

	const handleBudgetItems = () => {
		return budgetItems?.output?.map(
			(item: any) =>
				item?.CODIGO && (
					<ItemContainer key={item?.CODIGO}>
						<ItemCode>
							<ItemHeader>Codigo</ItemHeader>
							<Text>{item?.CODIGO}</Text>
						</ItemCode>
						<ItemDescription>
							<ItemHeader>Descrição</ItemHeader>
							<Text>{item?.NOME}</Text>
						</ItemDescription>
						<Quantity>
							<ItemHeader>Quantidade</ItemHeader>
							<Text>{item?.QUANTIDADE ?? '1'}</Text>
						</Quantity>
						<ItemPrice>
							<ItemHeader>Preço</ItemHeader>
							<Text>
								{isResale
									? formatPrice(handlePrice(item?.PRECO))
									: formatPrice(item?.PRECO.replace(/[,.]/g, ''))}
							</Text>
						</ItemPrice>
						<Button
							text="Adicionar"
							isAdded={handleItemAdded(item.CODIGO)}
							onClick={() => addOrderItem(item)}
						/>
					</ItemContainer>
				)
		);
	};

	const displayClientData = () => {
		if (clientModal) {
			return clientData?.output?.map(
				(client: any) =>
					client?.CODIGO && (
						<ItemContainer key={client?.CODIGO}>
							<ClientDocument>
								<ItemHeader>CPF</ItemHeader>
								<Text>{client?.CPF_CNPJ}</Text>
							</ClientDocument>
							<ClientName>
								<ItemHeader>NOME</ItemHeader>
								<Text>
									{client?.NOME} {client?.NOME}
								</Text>
							</ClientName>
							<ClientInfo>
								<ItemHeader>EMAIL</ItemHeader>
								<Text>{client?.EMAIL}</Text>
							</ClientInfo>
							{(client.TELEFONE || client.CELULAR) && (
								<ClientInfo>
									<ItemHeader>TELEFONE</ItemHeader>
									<Text>
										{client.DDD}{' '}
										{client.CELULAR ? client.CELULAR : client.Telefone}
									</Text>
								</ClientInfo>
							)}
							<Button
								text="Adicionar"
								isAdded={clientIsAdded(client.CPF_CNPJ)}
								onClick={() => setClientData(client)}
							/>
						</ItemContainer>
					)
			);
		}
	};

	return (
		<ModalContainer
			titleAriaId={titleId}
			isOpen={isModalOpen}
			onDismiss={hideModal}
			isBlocking={false}
		>
			<ModalHeader>
				<IconButton
					iconProps={cancelIcon}
					ariaLabel="Close popup modal"
					onClick={hideModal}
				/>
			</ModalHeader>
			<ModalContent>
				<Title text={title} />
				{!clientModal && (
					<HorizontalFlex>
						<InputContainer>
							{isResale ? (
								<Input
									label="Código ou nome do produto de revenda"
									errorMessage="Produto não encontrado"
									error={formErrors.offerId}
									onFocus={() => clearFormErrors('offerId')}
									onChange={(e, value) => handleBudgetItemId(value)}
								/>
							) : (
								<Input
									label="ID do orçamento"
									errorMessage="Orçamento não encontrado"
									mask="99/9999999"
									error={formErrors.offerId}
									onFocus={() => clearFormErrors('offerId')}
									onChange={(e, value) => handleBudgetItemId(value)}
								/>
							)}
							<Switch
								checked={isResale}
								onText="Revenda"
								offText="Orçamento"
								onChange={(e, value) => setIsResale(value)}
							/>
						</InputContainer>
						<ButtonContainer>
							<Button
								text="Importar"
								onClick={() => getBudgetItems()}
								loading={loading}
							/>
						</ButtonContainer>
					</HorizontalFlex>
				)}

				{clientModal && (
					<HorizontalFlex>
						<InputContainer>
							<Input
								label="Digite o CPF do cliente"
								errorMessage="Cliente não encontrado"
								error={formErrors.offerId}
								mask="999.999.999-99"
								onFocus={() => clearFormErrors('offerId')}
								onChange={(e, value) => handleClientCpf(value)}
							/>
						</InputContainer>
						<ButtonContainer>
							<Button
								text="Importar"
								onClick={() => getClientBudgetData()}
								loading={loadingClient}
							/>
						</ButtonContainer>
					</HorizontalFlex>
				)}
			</ModalContent>
			{clientModal ? (
				<ModalItems>{displayClientData()}</ModalItems>
			) : (
				<ModalItems>{handleBudgetItems()}</ModalItems>
			)}
		</ModalContainer>
	);
};

export default Modal;
