import styled from 'styled-components';
import { ComboBox, TextField, IconButton } from '@fluentui/react';
import Button from '../../../../../common/ui/button';

export const ListHeader = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	border-bottom: solid 1px rgb(243, 242, 241);
	border-radius: 3px;
	padding: 12px 0;
	gap: 15px;
	width: 100%;
	justify-content: space-between;
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: flex-end;
	max-width: 980px;
	width: 100%;
	margin-left: 26px;
	gap: 10px;
`;

export const Input = styled.div`
	max-width: 100%;
	position: relative;
	width: 100%;
`;

export const Field = styled(TextField)``;

export const ButtonContainer = styled.div`
	/* min-width: 160px; */
`;

export const FilterButton = styled(Button)``;

export const Select = styled(ComboBox)`
	position: relative;
	min-width: 120px;
	bottom: 5px;
`;

export const DatePickerContainer = styled.div`
	display: flex;
	align-items: flex-end;
	position: relative;
	margin-right: 26px;
	bottom: -5px;
	gap: 15px;
`;

export const Date = styled.div`
	min-width: 210px;
`;

export const ClearCode = styled(IconButton)`
	color: #fff;
	background: ${(props) => props.theme.colors.primary};
	width: 20px;
	height: 20px;
	margin-left: -5px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border: solid 1px;
	border-color: ${(props) => props.theme.colors.primary};
	right: 8px;

	i {
		font-size: 10px;
	}

	&:hover {
		color: #fff;
		background: ${(props) => props.theme.colors.secondary};
	}
`;
