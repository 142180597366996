import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from 'react-router-dom';
import { ThemeProvider, PartialTheme } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ThemeProvider as StyledProvider } from 'styled-components';
import GlobalStyle from './globalStyle';
import Autentication from './utils/autentication';

import Navigation from './containers/navigation';
import Header from './containers/header';
import LoagingPage from './pages/loadingPage';
import CreateOrder from './pages/admin/createOrder';
import Orders from './pages/admin/orders';
import Report from './pages/admin/report';
import roval from './common/themes/roval';
import i9tech from './common/themes/i9tech';
import Payments from './pages/admin/payments';
import PrintOrder from './pages/admin/orders/containers/printOrder';
import SuccessPage from './pages/Payment/Success';
import RootPage from './pages';

initializeIcons();

const rovalTheme: PartialTheme = {
	palette: {
		themePrimary: '#C10230',
		themeDarkAlt: '#d40638',
		themeDark: '#d40638',
	},
};

export const App: React.FunctionComponent = () => {
	const [isAutenticated, setIsAuthenticated] = useState(true);
	const [homeAccount, setHomeAccount] = useState({});
	let theme = 'roval';

	return (
		<Router>
			<ThemeProvider theme={theme === 'roval' ? rovalTheme : undefined}>
				<StyledProvider theme={theme === 'roval' ? roval : i9tech}>
					<GlobalStyle />
					<RootPage />
				</StyledProvider>
			</ThemeProvider>
		</Router>
	);
};
