import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import { cpfMask } from '../../../../../common/utils/masks';

const styles = StyleSheet.create({
	pageContent: {
		padding: '1%',
		width: '50%',
		textTransform: 'uppercase',
	},
	infoHeaderTop: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		border: '1px solid black',
		fontSize: '10px',
		marginBottom: 20,
		padding: 5,
		fontFamily: 'Helvetica-Bold',
	},
	infoHeaderCenterFirst: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		border: '1px solid black',
		fontSize: '10px',
	},
	infoHeaderCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		border: '1px solid black',
		fontSize: '10px',
		borderTop: 'none',
	},
	infoHeaderCenterLast: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		border: '1px solid black',
		fontSize: '10px',
		borderTop: 'none',
		marginBottom: 20,
	},
	infoHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		backgroundColor: '#eaedf0',
		paddingHorizontal: 18,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
	},
	headerInfoContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 5,
	},
	headerTitle: {
		borderRight: '1px solid black',
		height: '100%',
		width: 100,
		padding: 5,
		fontFamily: 'Helvetica-Bold',
	},
	headerBoldText: {
		padding: 5,
	},
	boldTextTitle: {
		fontWeight: 900,
		fontSize: '10px',
	},
	boldText: {
		fontWeight: 'bold',
		fontSize: '10px',
		textOverflow: 'ellipsis',
		maxWidth: 150,
	},
	boldTextTable: {
		fontWeight: 'bold',
		fontSize: '8px',
		textOverflow: 'ellipsis',
		maxWidth: 150,
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 30,
		marginHorizontal: -5, // to simulate gap between children
	},
	tableContainer: {
		width: '100%',
	},
	tableRowHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		borderBottom: '1px solid black',
		borderTop: '1px solid black',
		fontFamily: 'Helvetica-Bold',
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		borderBottom: '1px solid black',
	},
	tableCol1: {
		flex: 0.4,
		borderLeft: '1px solid black',
		padding: 5,
		height: '100%',
	},
	tableCol2: {
		flex: 0.15,
		borderLeft: '1px solid black',
		padding: 5,
		textAlign: 'center',
		height: '100%',
	},
	tableCol3: {
		flex: 0.1,
		borderLeft: '1px solid black',
		padding: 5,
		textAlign: 'center',
		height: '100%',
	},
	tableCol4: {
		flex: 0.1,
		borderLeft: '1px solid black',
		padding: 5,
		textAlign: 'center',
		height: '100%',
	},
	tableCol5: {
		flex: 0.2,
		borderLeft: '1px solid black',
		padding: 5,
		textAlign: 'center',
		height: '100%',
	},
	tableCol6: {
		flex: 0.2,
		padding: 5,
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		textAlign: 'center',
		height: '100%',
	},
});

export default function PrintComponent({ details }: { details: any }) {
	const itemsNoDelivery = details?.items?.filter(
		(item: any) =>
			item.description !== 'Frete' && item.description !== 'Desconto'
	);
	const deliveryValue = details?.items?.filter(
		(item: any) => item.description === 'Frete'
	);
	const discountValue = details?.items?.filter(
		(item: any) => item.description === 'Desconto'
	);

	const getTotal = (): number => {
		let totalValue = 0;
		if (details?.items.length > 0) {
			details?.items?.map((order: any) => {
				if (order.description !== 'Desconto' && order.description !== 'Frete') {
					const amount = parseInt(order.amount);
					totalValue += amount * order.quantity;
				}
				return totalValue;
			});
		}
		return totalValue;
	};

	const getTotalWithDiscount = (): number => {
		let totalValue = 0;
		if (details) {
			details?.items?.map((order: any) => {
				if (order.description !== 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue += amount * order.quantity;
				}
				if (order.description === 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue -= amount;
				}
				return totalValue;
			});
		}
		return totalValue;
	};

	const handleStatus = (status) => {
		if (status === 'pending') return 'Pendente';
		if (status === 'failed') return 'Falha';
		if (status === 'canceled') return 'Cancelado';
		return 'Pago';
	};

	return (
		<Document>
			<Page size="A4">
				<View style={styles.pageContent}>
					<View style={styles.infoHeaderTop}>
						<View style={styles.headerInfoContainer}>
							<Text style={styles.boldTextTable}>Pedido: </Text>
							<Text style={styles.boldTextTable}>{details?.id}</Text>
						</View>
					</View>

					<View style={styles.infoHeaderCenterFirst}>
						<View style={styles.headerInfoContainer}>
							<View style={styles.headerTitle}>
								<Text style={styles.boldTextTable}>Data de criação:</Text>
							</View>
							<Text style={styles.boldTextTable}>
								{moment(details?.created_at).format('DD/MM/YYYY - hh:mm:ss')}
							</Text>
						</View>
					</View>

					<View style={styles.infoHeaderCenter}>
						<View style={styles.headerInfoContainer}>
							<View style={styles.headerTitle}>
								<Text style={styles.boldTextTable}>Última alteração:</Text>
							</View>
							<Text style={styles.boldTextTable}>
								{moment(details?.updated_at).format('DD/MM/YYYY - hh:mm:ss')}
							</Text>
						</View>
					</View>

					<View style={styles.infoHeaderCenter}>
						<View style={styles.headerInfoContainer}>
							<View style={styles.headerTitle}>
								<Text style={styles.boldTextTable}>Cliente:</Text>
							</View>
							<View>
								<Text style={styles.boldTextTable}>
									{details?.customer?.name}
								</Text>
								<Text
									style={{
										...styles.boldTextTable,
										textTransform: 'lowercase',
									}}
								>
									{details?.customer?.email}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.infoHeaderCenter}>
						<View style={styles.headerInfoContainer}>
							<View style={styles.headerTitle}>
								<Text style={styles.boldTextTable}>Status:</Text>
							</View>
							<Text style={styles.boldTextTable}>
								{handleStatus(details.status)}
							</Text>
						</View>
					</View>

					{!!details.charges?.find((c) => c.status === 'paid') && (
						<View style={styles.infoHeaderCenter}>
							<View style={styles.headerInfoContainer}>
								<View style={styles.headerTitle}>
									<Text style={styles.boldTextTable}>Pago em:</Text>
								</View>
								<Text style={styles.boldTextTable}>
									{moment(
										details.charges.find((c) => c.status === 'paid').paid_at
									).format('DD/MM/YYYY - hh:mm:ss')}
								</Text>
							</View>
						</View>
					)}

					<View style={styles.infoHeaderCenterLast}>
						<View style={styles.headerInfoContainer}>
							<View style={styles.headerTitle}>
								<Text style={styles.boldTextTable}>Total:</Text>
							</View>
							<Text style={styles.boldTextTable}>
								{formatPrice(getTotalWithDiscount())}
							</Text>
						</View>
					</View>

					<View style={styles.tableContainer}>
						<View style={styles.tableRowHeader}>
							<View style={styles.tableCol1}>
								<Text style={styles.boldTextTable}>Produto</Text>
							</View>
							<View style={styles.tableCol2}>
								<Text style={styles.boldTextTable}>Código</Text>
							</View>
							<View style={styles.tableCol3}>
								<Text style={styles.boldTextTable}>Qtd</Text>
							</View>
							<View style={styles.tableCol5}>
								<Text style={styles.boldTextTable}>Valor</Text>
							</View>
							<View style={styles.tableCol6}>
								<Text style={styles.boldTextTable}>Total</Text>
							</View>
						</View>

						{itemsNoDelivery?.map((item) => (
							<View style={styles.tableRow}>
								<View style={styles.tableCol1}>
									<Text style={styles.boldTextTable}>{item.description}</Text>
								</View>
								<View style={styles.tableCol2}>
									<Text style={styles.boldTextTable}>{item.code}</Text>
								</View>
								<View style={styles.tableCol3}>
									<Text style={styles.boldTextTable}>{item.quantity}</Text>
								</View>
								<View style={styles.tableCol5}>
									<Text style={styles.boldTextTable}>
										{formatPrice(item.amount)}
									</Text>
								</View>
								<View style={styles.tableCol6}>
									<Text style={styles.boldTextTable}>
										{formatPrice(item.amount * item.quantity)}
									</Text>
								</View>
							</View>
						))}
					</View>
				</View>
			</Page>
		</Document>
	);
}
