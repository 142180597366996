import { useState } from 'react';

const usePagination = () => {
	const [pageNumber, setPageNumber] = useState(1);

	const handlePagination = (updatedNumber: number) =>
		setPageNumber(updatedNumber);

	return [pageNumber, handlePagination];
};

export default usePagination;
