import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

export const Container = styled(Stack)`
	max-width: 300px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	padding-right: 4%;
	gap: 10px;
`;

export const Icon = styled(IconButton)`
	color: #333;

	&:hover {
		color: #1f1d1d;
		background: #dbd5d5;
	}
`;
