import React from 'react';
import { IIconProps } from '@fluentui/react';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { useId } from '@fluentui/react-hooks';
import { Item } from '../../types/index';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import OrderTaxes from '../orderTaxes';

import {
	Container,
	ItemDescription,
	ItemText,
	ItemCode,
	ItemValue,
	UnityPrice,
	DeleteButton,
	ItemContainer,
	ItemName,
	CreateOrderContainer,
	OrderInfo,
	InfoHeader,
	DescriptionContainer,
	InputsContainer,
	OfferIdInput,
	SeparatorLine,
	Tooltip,
	Icon,
	TooltipContainer,
	DeleteButtonContainer,
	AddMoreItems,
	TaxesOptionsContainer,
	ChangeQuantity,
	OrdersContainer,
	OrderTotal,
	TotalText,
	TotalTitle,
	SectionTitle,
} from './style';

interface IOrderItemsProps {
	items: Item[];
	offerId: string;
	total: number;
	removeItem: (code?: string) => void;
	showModal: () => void;
	getOrderTotal: () => void;
	updateQuantity: (id: string | undefined, value: string | undefined) => void;
	setOfferId: any;
}

const deleteIcon: IIconProps = { iconName: 'Delete' };

const OrderItems: React.FunctionComponent<IOrderItemsProps> = ({
	items,
	offerId,
	removeItem,
	updateQuantity,
	setOfferId,
	total,
	showModal,
	getOrderTotal,
}) => {
	const tooltipId = useId('tooltip');
	const calloutProps = { gapSpace: 0 };

	return (
		<CreateOrderContainer>
			<OrdersContainer hasItems={items.length > 0}>
				<SectionTitle variant="xLarge">Detalhes do pedido</SectionTitle>
				<OrderInfo hasItems={items.length > 0}>
					<InputsContainer>
						<OfferIdInput
							placeholder="Código de referência do pedido"
							value={offerId ?? ''}
							onChange={(e, value) => setOfferId(value)}
						/>
						<TooltipContainer>
							<Tooltip
								content="O pedido criará um ID aleatório caso não informado."
								id={tooltipId}
								calloutProps={calloutProps}
								setAriaDescribedBy={false}
							>
								<Icon aria-label="Info" iconName="Info" />
							</Tooltip>
						</TooltipContainer>
					</InputsContainer>
					<AddMoreItems text="Importar item" onClick={showModal} />
				</OrderInfo>
				{items.length > 0 && <SeparatorLine>Itens Adicionados</SeparatorLine>}
				<Container hasItems={items.length > 0}>
					{items.map(
						(item) =>
							item.code && (
								<ItemContainer key={item.code}>
									<ItemDescription>
										<ItemCode>
											<InfoHeader>CÓDIGO</InfoHeader>{' '}
											<ItemText>{item.code}</ItemText>
										</ItemCode>
										<ItemName>
											<InfoHeader>DESCRIÇÃO </InfoHeader>
											<DescriptionContainer>
												<ItemText>{item.description}</ItemText>
											</DescriptionContainer>
										</ItemName>
										<ChangeQuantity>
											<InfoHeader>QUANTIDADE</InfoHeader>
											<SpinButton
												value={item.quantity?.toString()}
												min={1}
												step={1}
												onChange={(e, value) =>
													updateQuantity(item.code, value)
												}
												incrementButtonAriaLabel="Increase value by 1"
												decrementButtonAriaLabel="Decrease value by 1"
											/>
										</ChangeQuantity>
									</ItemDescription>
									<ItemValue>
										<InfoHeader>PREÇO</InfoHeader>
										<UnityPrice variant="mediumPlus">
											{formatPrice(item.amount, item.quantity)}
										</UnityPrice>
									</ItemValue>
									<DeleteButtonContainer>
										<DeleteButton
											iconProps={deleteIcon}
											title="Remover"
											ariaLabel="Remover"
											onClick={() => removeItem(item.code)}
										/>
									</DeleteButtonContainer>
								</ItemContainer>
							)
					)}
				</Container>
			</OrdersContainer>
			{items.length > 0 && (
				<TaxesOptionsContainer>
					<OrderTaxes total={total} getOrderTotal={getOrderTotal} />
					<OrderTotal>
						<TotalTitle>Total: </TotalTitle>
						<TotalText>{formatPrice(total)}</TotalText>
					</OrderTotal>
				</TaxesOptionsContainer>
			)}
		</CreateOrderContainer>
	);
};

export default OrderItems;
