/**
 * Nome e sobrenome valido
 */
export const validName = (value: string) => {
	if (!value) return false;
	if (value.match(/[0-9]+/g)) return false;
	if (value.length < 5) return false;
	if (!value.match(' ')) return false;

	return true;
};

/**
 * Valido cpf
 */
export const isValidCpf = (strCPF: string) => {
	let sum: any;
	let remaining: any;
	let i: any;
	sum = 0;
	let cpfPure = strCPF.replace(/[,.-]/g, '');

	if (cpfPure === '00000000000') return false;
	for (i = 1; i <= 9; i++)
		sum += parseInt(cpfPure.substring(i - 1, i)) * (11 - i);
	remaining = (sum * 10) % 11;
	if (remaining === 10 || remaining === 11) remaining = 0;
	if (remaining !== parseInt(cpfPure.substring(9, 10))) return false;
	sum = 0;
	for (i = 1; i <= 10; i++)
		sum += parseInt(cpfPure.substring(i - 1, i)) * (12 - i);
	remaining = (sum * 10) % 11;
	if (remaining === 10 || remaining === 11) remaining = 0;
	if (remaining !== parseInt(cpfPure.substring(10, 11))) return false;
	return true;
};
