import React, { useEffect } from 'react';
import { Text, IIconProps } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { FormErrors } from '../../types/index';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import { postOrderRequest } from '../../../../../common/redux/createOrder/actions';
import { createOrderData } from '../../../../../common/utils/formatted';
import CustomButton from '../../../../../common/ui/button';
import CustomTitle from '../../../../../common/ui/title/index';
import { cpfMask } from '../../../../../common/utils/masks';

import {
	Container,
	StepControls,
	Button,
	PaymentsInfo,
	Customer,
	ColumnFlex,
	Payments,
	OrderResume,
	Header,
	Info,
	ItemDescription,
	ItemPrice,
	HorizontalFlex,
	CustomText,
	Total,
	List,
	DeliveryPrice,
	ControlsContainer,
	ErrorMessage,
	InfoTitle,
	TaxesText,
} from './style';

interface IPaymentInfoProps {
	step: number | undefined;
	paymentMethods: string[];
	paymentType: any;
	formErrors: FormErrors;
	orderTotal: number;
	items: any;
	loading: boolean;
	offerId: string;
	handleStep: (step: number) => void;
	setFormErrors: (newState: FormErrors) => void;
	shippingPrice?: number;
	discountPrice?: number;
	clientInfo: any;
}

const chevronLeft: IIconProps = { iconName: 'ChevronLeftMed' };

const PaymentResume: React.FunctionComponent<IPaymentInfoProps> = ({
	step,
	handleStep,
	paymentType,
	paymentMethods,
	formErrors,
	loading,
	orderTotal,
	clientInfo,
	setFormErrors,
	discountPrice,
	offerId,
	shippingPrice,
	items,
	...props
}) => {
	const dispatch = useDispatch();
	const handlePaymentMethod = (method: string) => {
		if (method === 'pix') return 'Pix';
		if (method === 'credit_card') return 'Cartão de Crédito';
		return 'Boleto';
	};

	const handleCreateOrder = async (step: number) => {
		const body = createOrderData(
			items,
			clientInfo,
			paymentMethods,
			paymentType,
			orderTotal,
			shippingPrice,
			offerId,
			discountPrice
		);
		await dispatch<any>(postOrderRequest(body, handleStep, setFormErrors));
	};

	const shipping = () => {
		if (shippingPrice && shippingPrice > 0) {
			return (
				<DeliveryPrice>
					<TaxesText>Frete</TaxesText>
					<Text variant="medium">{formatPrice(shippingPrice)}</Text>
				</DeliveryPrice>
			);
		}
	};

	const discount = () => {
		if (discountPrice && discountPrice > 0) {
			return (
				<DeliveryPrice>
					<TaxesText>Desconto</TaxesText>
					<Text variant="medium">{formatPrice(discountPrice)}</Text>
				</DeliveryPrice>
			);
		}
	};

	useEffect(() => {
		if (!formErrors.createOrder) return;

		const intervalId = setInterval(() => {
			setFormErrors({
				createOrder: false,
			});
		}, 3000);

		return () => clearInterval(intervalId);
	}, [formErrors.createOrder, setFormErrors]);

	return (
		<Container {...props} showStep={step === 3}>
			<CustomTitle text="Confirme os dados do pedido para finalizar" />
			<Customer>
				<OrderResume>
					<Header>Cliente</Header>
					<Info>
						<Text variant="mediumPlus">{clientInfo.name}</Text>
						<ColumnFlex>
							<InfoTitle>Email</InfoTitle>
							<Text>- {clientInfo.email}</Text>
						</ColumnFlex>
						<ColumnFlex>
							<InfoTitle>CPF</InfoTitle>
							<Text>- {cpfMask(clientInfo.cpf)}</Text>
						</ColumnFlex>
					</Info>
				</OrderResume>

				<OrderResume>
					<Header>Detalhes de Pagamento</Header>
					<Info>
						<Text variant="mediumPlus">Link de Pagamento</Text>
						<ColumnFlex>
							<InfoTitle>Gerar link, permitindo:</InfoTitle>
							<Payments>
								{paymentMethods.map((method) => (
									<Text key={method} variant="small">
										- {handlePaymentMethod(method)}
									</Text>
								))}
							</Payments>

							{paymentMethods.includes('credit_card') && (
								<PaymentsInfo>
									<InfoTitle>Operação do Cartão:</InfoTitle>
									<List>
										<Text variant="small">- Autorizar e Capturar</Text>
									</List>
								</PaymentsInfo>
							)}

							{paymentMethods.includes('pix') && (
								<PaymentsInfo>
									<Text>Expiração do pix:</Text>
									<List>
										<Text variant="small">- {paymentType.expiresAt}</Text>
									</List>
								</PaymentsInfo>
							)}

							{paymentMethods.includes('boleto') && (
								<PaymentsInfo>
									<Text>Expiração do boleto:</Text>
									<List>
										<Text variant="small">- {paymentType.dueAt}</Text>
									</List>
								</PaymentsInfo>
							)}
						</ColumnFlex>
					</Info>
				</OrderResume>

				<OrderResume itemResume>
					<Header>Itens do Pedido</Header>
					<Info>
						<ColumnFlex itemsGap>
							{items.map((item: any) => (
								<HorizontalFlex key={item.code}>
									<ItemDescription>
										<CustomText>
											{item.description} <span>x{item.quantity}</span>
										</CustomText>
									</ItemDescription>
									<ItemPrice>
										<Text>{formatPrice(item.amount, item.quantity)}</Text>
									</ItemPrice>
								</HorizontalFlex>
							))}
							{discount()}
							{shipping()}
							<Total>
								<Text variant="mediumPlus">Total</Text>
								<Text variant="mediumPlus">{formatPrice(orderTotal)}</Text>
							</Total>
						</ColumnFlex>
					</Info>
				</OrderResume>
			</Customer>
			<StepControls>
				<ErrorMessage hasError={formErrors.createOrder}>
					Erro: Verifique os dados inseridos ou tente novamente mais tarde.
				</ErrorMessage>
				<ControlsContainer>
					<Button
						iconProps={chevronLeft}
						text="Voltar"
						onClick={() => handleStep(2)}
					/>
					<CustomButton
						loading={loading}
						text="Gerar Link"
						onClick={() => handleCreateOrder(4)}
					/>
				</ControlsContainer>
			</StepControls>
		</Container>
	);
};

export default PaymentResume;
