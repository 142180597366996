import { Reducer } from 'redux';
import { GetEmbeddedTypes, GetEmbeddedState } from './types';

const INITIAL_STATE: GetEmbeddedState = {
	embeddedInfo: undefined,
};

/**
 * GET request para criar o pedido
 */
export const getEmbeddedReport: Reducer<GetEmbeddedState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case GetEmbeddedTypes.GET_EMBEDDED_START:
			return {
				...state,
			};
		case GetEmbeddedTypes.GET_EMBEDDED_SUCCESS:
			return {
				...state,
				embeddedInfo: action.payload,
			};
		case GetEmbeddedTypes.GET_EMBEDDED_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
};
