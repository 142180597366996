import styled from 'styled-components';
import { Separator } from '@fluentui/react/lib/Separator';
import { Text, DefaultButton } from '@fluentui/react';

type Props = {
	smallInput?: boolean;
	hasError?: boolean;
	noItems?: boolean;
};

export const InputContainer = styled.div<Props>`
	width: ${({ smallInput }) => (smallInput ? '49%' : '100%')};
	@media (max-width: 535px) {
		width: ${({ smallInput }) => smallInput && '100%'};
	}
`;

export const SeparatorLine = styled(Separator)`
	margin-top: 20px;
	margin-bottom: -20px;
`;

export const HorizontalFlex = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;
	justify-content: space-between;
	margin-top: 25px;

	@media (max-width: 535px) {
		flex-direction: column;
		margin-top: 5px;
	}
`;

export const Form = styled.div`
	width: 95%;
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 25px;
	padding-bottom: 30px;
	border: solid 1px #e6e6e4;
	border-radius: 3px;
	margin-bottom: 20px;
	box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 2%), 0 0.3px 0.9px 0 rgb(0 0 0 / 3%);
	@media (max-width: 535px) {
		padding-top: 30px;
	}
`;

export const SectionTitle = styled(Text)`
	align-self: center;
`;

export const ProductForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;
`;

export const AddButton = styled.div``;

export const ErrorMessage = styled.span<Props>`
	opacity: ${({ hasError }) => (hasError ? '1' : '0')};
	pointer-events: ${({ hasError }) => (hasError ? 'unset' : 'none')};
	margin-bottom: 15px;
	margin-top: -6px;
	font-size: 12px;
	font-weight: 400;
	color: rgb(164, 38, 44);
	position: absolute;
	left: 28px;
	bottom: -10px;
	transition: opacity 0.17s ease;
`;

export const AddMoreItems = styled(DefaultButton)`
	position: relative;
`;

export const Buttons = styled.div<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 15px;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: auto;
`;
