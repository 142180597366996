import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './style';

interface ILoadingPageProps {
	theme?: string;
}

const LoadingPage: React.FunctionComponent<ILoadingPageProps> = ({ theme }) => (
	<Container>
		<ReactLoading
			type="bubbles"
			color={theme === 'roval' ? '#C10230' : 'black'}
		/>
	</Container>
);

export default LoadingPage;
