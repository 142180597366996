import styled from 'styled-components';
import {
	Stack,
	Checkbox,
	ComboBox,
	TextField,
	ChoiceGroup,
} from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';

type Props = {
	hasCreditCard?: boolean;
};

export const Container = styled(Stack)<Props>`
	display: flex;
	width: 100%;
	max-width: 300px;
	border-radius: 2px;
	border: solid 1px #c5c1bf;
	min-height: 368px;
	margin-top: 10px;
	opacity: ${({ hasCreditCard }) => (hasCreditCard ? '1' : '0.6')};
`;

export const CardHeader = styled.div`
	display: flex;
	align-items: center;
	border-bottom: solid 1px rgb(243, 242, 241);
	padding: 14px 16px;
	position: relative;
`;

export const HeaderText = styled.div`
	display: flex;
	margin: 0 auto;
	align-items: center;
`;

export const Icon = styled(FontIcon)`
	font-size: 18px;
	margin-right: 10px;
	position: relative;
	top: 1px;
`;

export const CheckboxButton = styled(Checkbox)`
	position: absolute;
	left: 12px;
`;

export const Installments = styled.div``;

export const SoftDescriptor = styled.div`
	margin-top: 25px;
`;

export const CardBody = styled.div`
	padding: 14px 16px;
	padding-bottom: 50px;
`;

export const Select = styled(ComboBox)`
	margin-top: 5px;
`;

export const Field = styled(TextField)`
	margin-top: 5px;
`;

export const Operation = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
`;

export const OperatorCheckBox = styled(ChoiceGroup)`
	margin-top: 10px;
`;
