import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../../../common/ui/input';
import {
	handleDiscountPrice,
	handleShippingPrice,
} from '../../../../../common/redux/createOrder/actions';

import {
	TaxesContainer,
	InputsContainer,
	DiscountContainer,
	Switch,
	DiscountInput,
	Percentage,
} from './style';

interface IOrderTaxesProps {
	total: number;
	getOrderTotal: () => void;
}

const OrderTaxes: React.FunctionComponent<IOrderTaxesProps> = ({
	total,
	getOrderTotal,
}) => {
	const [isPercentage, setIsPercentage] = useState<boolean | undefined>(false);
	const dispatch = useDispatch();

	const getShippingPrice = (value: string) => {
		const shippingValue = value.replace('R$', '');
		const valueFormatted = parseInt(shippingValue.replace(/[,.]/g, ''));
		dispatch(handleShippingPrice(valueFormatted));
	};

	const getDiscountPrice = (value: string) => {
		let newDiscount = 0;
		if (value) {
			const discountValue = value.replace('R$', '');
			const valueFormatted = parseInt(discountValue.replace(/[,.]/g, ''));
			if (isPercentage) {
				newDiscount = (valueFormatted * total) / 100;
				const stringDiscount = newDiscount.toString();
				newDiscount = parseInt(stringDiscount);
			} else {
				newDiscount = valueFormatted;
			}
		}

		dispatch(handleDiscountPrice(newDiscount));
	};

	return (
		<TaxesContainer>
			<DiscountContainer>
				{!isPercentage ? (
					<DiscountInput>
						<Input
							maskType="currency"
							errorMessage="Informe o valor"
							label="Desconto"
							onBlur={getOrderTotal}
							onChange={(e) => getDiscountPrice(e.target.value)}
						/>
					</DiscountInput>
				) : (
					<DiscountInput>
						<Input
							errorMessage="Informe o valor"
							mask="999"
							label="Desconto"
							onBlur={getOrderTotal}
							onChange={(e) => getDiscountPrice(e.target.value)}
						/>
						<Percentage>%</Percentage>
					</DiscountInput>
				)}

				<Switch
					onText="Porcentagem"
					offText="Real"
					onChange={(e, value) => setIsPercentage(value)}
				/>
			</DiscountContainer>

			<InputsContainer>
				<Input
					maskType="currency"
					errorMessage="Informe o valor"
					label="Frete"
					onBlur={getOrderTotal}
					onChange={(e) => getShippingPrice(e.target.value)}
				/>
			</InputsContainer>
		</TaxesContainer>
	);
};

export default OrderTaxes;
