import React, { useState, useEffect } from 'react';
import { Text, IComboBoxOption } from '@fluentui/react';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import { FormErrors } from '../../types';

import { getTableData } from '../../../../../config/storage';

import {
	Container,
	CardHeader,
	HeaderText,
	Icon,
	CheckboxButton,
	CardBody,
	Select,
	Installments,
	SoftDescriptor,
	Field,
} from './style';

interface ICreditCardProps {
	handlePaymentMethods: (checked: any, newState: string) => void;
	setPaymentType: (id: string, value: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	orderTotal: number;
	formErrors: FormErrors;
	paymentMethods: string[];
}

interface IInstallmentsProps {
	key: string;
	text: string;
}

interface IFormattedInstallments {
	number: number;
	total: number;
}

const CreditCard: React.FunctionComponent<ICreditCardProps> = ({
	handlePaymentMethods,
	setPaymentType,
	setFormErrors,
	formErrors,
	paymentMethods,
	orderTotal,
}) => {
	const [installments, setInstallments] = useState<IInstallmentsProps[]>([]);
	const [formattedInstallments, setFormattedInstallments] = useState<
		IFormattedInstallments[]
	>([]);
	const [userParcelamento, setUserParcelamento] = useState(false);

	let hasCreditCard = paymentMethods && paymentMethods.includes('credit_card');

	const options: IComboBoxOption[] = installments;

	async function loadParcelamento() {
		try {
			const emails = await getTableData();

			let userEmail = JSON.parse(localStorage.getItem('account') as string)
				?.account?.username;

			if (userEmail && emails.find((e) => e === userEmail)) {
				console.log('userParcelamento');
				setUserParcelamento(true);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		loadParcelamento();
	}, []);

	const definedMaxInstallments = (total: number): number => {
		if (userParcelamento) return 6;

		if (total < 10000) return 1;

		if (total > 10001 && total <= 20000) return 2;

		if (total > 20001 && total <= 50000) return 3;

		if (total > 50001 && total <= 80000) return 4;

		if (total > 80001 && total <= 100000) return 5;

		return 6;
	};

	const getInstallments = (total: number) => {
		let maxParcel = definedMaxInstallments(total);
		const items: any[] = [];
		const itemsFormatted: any[] = [];

		for (let i = 1; i <= maxParcel; i++) {
			const parcel = total / i;

			items.push({
				key: i.toString(),
				text: `${i}x de ${formatPrice(parseInt(parcel.toString()))} sem juros`,
			});

			itemsFormatted.push({
				number: i,
				total: orderTotal,
			});
		}

		setInstallments(items);
		setFormattedInstallments(itemsFormatted);
	};

	const handleInstallments = (value: any) => {
		const quantity = parseInt(value.key);
		const installments = formattedInstallments;
		setPaymentType('installments', installments.slice(0, quantity));
	};

	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	useEffect(() => {
		getInstallments(orderTotal);
		setPaymentType('installments', '');
	}, [orderTotal]);

	return (
		<Container hasCreditCard={hasCreditCard}>
			<CardHeader>
				<CheckboxButton
					checked={hasCreditCard}
					onChange={(e, value) => handlePaymentMethods(value, 'credit_card')}
				/>
				<HeaderText>
					<Icon aria-label="PaymentCard" iconName="PaymentCard" />
					<Text>Cartão de crédito</Text>
				</HeaderText>
			</CardHeader>
			<CardBody>
				<Installments>
					<Text>Máximo de parcelas (*)</Text>
					<Select
						selectedKey=""
						options={options}
						onFocus={() => clearFormErrors('installments')}
						disabled={!hasCreditCard}
						errorMessage={
							formErrors.installments ? 'Selecione uma parcela' : undefined
						}
						onChange={(e, value) => handleInstallments(value)}
					/>
				</Installments>
				<SoftDescriptor>
					<Text>Texto na Fatura (Soft Descriptor)</Text>
					<Field
						disabled={!hasCreditCard}
						onChange={(e, value) => setPaymentType('softDescriptor', value)}
					/>
				</SoftDescriptor>
			</CardBody>
		</Container>
	);
};

export default CreditCard;
