import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useOnClickOutside from '../../common/hooks/useClickOutside';
import { msalApp } from '../../utils/autentication';
import {
	Container,
	HeaderLogo,
	UserContainer,
	UserIcon,
	UserInfo,
	User,
	InfoIcon,
	InfoContainer,
	InfoHeader,
	LogoutButton,
} from './style';

interface IHeaderProps {
	theme?: string;
	homeAccount: any;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
	theme,
	homeAccount,
}) => {
	const [open, setOpen] = useState(false);
	const infoRef = useRef<HTMLHeadingElement>(null);
	useOnClickOutside(infoRef, () => setOpen(false));

	const location = useLocation();

	console.log(location);

	const handleOpen = () => setOpen(!open);

	const handleLogout = () => {
		window.localStorage.clear();
		msalApp.logoutRedirect({
			account: homeAccount,
			postLogoutRedirectUri: 'https://web-payment.azurewebsites.net/',
		});
	};

	// if (location.pathname === '/pedidos/success') {
	// 	return <></>;
	// }

	return (
		<Container>
			<HeaderLogo
				src={
					theme === 'roval'
						? '/images/farmacia-roval.svg'
						: '/images/header-logo.svg'
				}
			/>

			<>
				<UserContainer>
					<UserIcon onClick={handleOpen}>
						{homeAccount?.idTokenClaims?.name?.charAt(0)}
					</UserIcon>
				</UserContainer>
				<UserInfo open={open} ref={infoRef}>
					<InfoContainer>
						<InfoHeader
							src={
								theme === 'roval'
									? '/images/farmacia-roval.svg'
									: '/images/header-logo.svg'
							}
						/>
						<LogoutButton onClick={handleLogout}>Sair</LogoutButton>
						<InfoIcon>{homeAccount?.idTokenClaims?.name?.charAt(0)}</InfoIcon>
						<User>{homeAccount?.idTokenClaims?.name}</User>
					</InfoContainer>
				</UserInfo>
			</>
		</Container>
	);
};

export default Header;
