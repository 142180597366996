import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import { CompoundButton } from '@fluentui/react/lib/Button';

interface Props {
	lastStep?: boolean;
	step?: number;
	selected?: boolean;
	stepTwo?: boolean;
}

export const Container = styled(Stack)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	background: #f4f7f9;
	padding: 45px 40px 50px 40px;
`;

export const ClientInfo = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;
	justify-content: space-between;
	padding-top: 50px;
`;

export const InputContainer = styled.div`
	width: 100%;
`;

export const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 960px;
	align-items: center;
	width: 100%;
	/* margin: 0 30px; */
	background: #fff;
	padding: 28px;
	height: 100%;
	border-radius: 2px;
	box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
`;

export const Stepper = styled.div<Props>`
	width: 100%;
	display: ${({ lastStep }) => (lastStep ? 'none' : 'block')};
`;

export const CreateOrderChoice = styled.div<Props>`
	display: ${({ selected }) => (selected ? 'none' : 'flex')};
	flex-direction: column;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	gap: 50px;
`;

export const ChoiceContainer = styled.div`
	display: flex;
	gap: 20px;
`;

export const ChoiceButton = styled(CompoundButton)`
	font-size: 22px;
	padding: 26px 20px;

	span {
		font-size: 16px;
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;

export const BudgetId = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 8px 12px;
	background: ${(props) => props.theme.colors.primary};
	border-bottom-left-radius: 2px;
	border-top-right-radius: 2px;
`;

export const BudgetText = styled.p`
	font-size: 12px;
	color: #fff;
	margin: 0;
`;
