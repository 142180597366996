import React, { useState, useEffect } from 'react';
import { Position } from '@fluentui/react';
import {
	ISpinButtonStyleProps,
	ISpinButtonStyles,
} from '@fluentui/react/lib/SpinButton';
import { IStyleFunction } from '@fluentui/react/lib/Utilities';
import { NumericField, ErrorTxt, SpinButtonContainer } from './style';

const styles: IStyleFunction<ISpinButtonStyleProps, ISpinButtonStyles> = (
	props: ISpinButtonStyleProps
) => {
	const { isFocused } = props;
	return {
		spinButtonWrapper: [
			{ border: 'solid 1px #c5c1bf', borderRadius: '2px' },
			isFocused && {
				border: '2px solid #0078d4',
				paddingBottom: '-1002px',
			},
		],
	};
};

interface ISpinButtonProps {
	onChange?: (e?: any, value?: any) => void;
	onFocus?: () => void;
	label?: string;
	placeholder?: string;
	errorMessage?: string;
	id?: string;
	error?: boolean;
	disabled?: boolean;
	minValue?: number;
	maxValue?: number;
	value?: string;
	defaultValue?: string;
}

const SpinButton: React.FunctionComponent<ISpinButtonProps> = ({
	onChange,
	onFocus,
	label,
	errorMessage,
	value,
	error,
	minValue,
	maxValue,
	// defaultValue,
}) => {
	const [errorText, setErrorText] = useState<string | undefined>('');
	const handleErrorText = () => {
		if (error) setErrorText(errorMessage);
		else setErrorText('');
	};

	useEffect(() => {
		handleErrorText();
	}, [error]);

	return (
		<SpinButtonContainer>
			<NumericField
				label={label}
				min={minValue}
				max={maxValue}
				labelPosition={Position.top}
				step={1}
				value={!value ? '0' : value}
				onFocus={onFocus}
				onChange={onChange}
				styles={styles}
			/>
			<ErrorTxt hasError={error}>{errorText}</ErrorTxt>
		</SpinButtonContainer>
	);
};

export default SpinButton;
