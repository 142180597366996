import React from 'react';
import { Container, PaginationBtn, PageButton, Dots } from './style';

interface IPaginationProps {
	handlePagination: any;
	page: any;
	totalPages: number;
	loading: boolean;
}

const Pagination: React.FunctionComponent<IPaginationProps> = ({
	handlePagination,
	totalPages,
	loading,
	page,
}) => {
	return (
		<Container hiddenPagination={totalPages <= 1}>
			<PaginationBtn
				onClick={() => handlePagination(page - 1)}
				disabled={page === 1 || loading}
				inactive={page === 1 || loading}
			>
				Anterior
			</PaginationBtn>
			<PageButton
				onClick={() => handlePagination(1)}
				active={page === 1}
				loading={loading ? 1 : 0}
			>
				{1}
			</PageButton>
			{page > 3 && <Dots>...</Dots>}
			{page === totalPages && totalPages > 3 && (
				<PageButton
					onClick={() => handlePagination(page - 2)}
					loading={loading ? 1 : 0}
				>
					{page - 2}
				</PageButton>
			)}
			{page > 2 && (
				<PageButton
					onClick={() => handlePagination(page - 1)}
					loading={loading ? 1 : 0}
				>
					{page - 1}
				</PageButton>
			)}
			{page !== 1 && page !== totalPages && (
				<PageButton
					onClick={() => handlePagination(page)}
					loading={loading ? 1 : 0}
					active
				>
					{page}
				</PageButton>
			)}
			{page < totalPages - 1 && (
				<PageButton
					onClick={() => handlePagination(page + 1)}
					loading={loading ? 1 : 0}
				>
					{page + 1}
				</PageButton>
			)}
			{page === 1 && totalPages > 3 && (
				<PageButton
					onClick={() => handlePagination(page + 2)}
					loading={loading ? 1 : 0}
				>
					{page + 2}
				</PageButton>
			)}
			{page < totalPages - 2 && <Dots>...</Dots>}
			<PageButton
				onClick={() => handlePagination(totalPages)}
				active={page === totalPages}
				loading={loading ? 1 : 0}
			>
				{totalPages}
			</PageButton>
			<PaginationBtn
				onClick={() => handlePagination(page + 1)}
				disabled={page === totalPages || loading}
				inactive={page === totalPages || loading}
			>
				Próximo
			</PaginationBtn>
		</Container>
	);
};

export default Pagination;
