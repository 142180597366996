import {
	Budget,
	ClientBudgetData,
} from '../../../pages/admin/createOrder/types';

/**
 * Action types
 */
export const CreateOrderTypes = {
	POST_ORDER_START: 'POST_ORDER_START',
	POST_ORDER_SUCCESS: 'POST_ORDER_SUCCESS',
	POST_ORDER_FAILURE: 'POST_GENRES_FAILURE',
	POST_BUDGET_START: 'POST_BUDGET_START',
	POST_BUDGET_SUCCESS: 'POST_BUDGET_SUCCESS',
	RESET_BUDGET: 'RESET_BUDGET',
	RESET_CLIENT: 'RESET_CLIENT',
	POST_BUDGET_FAILURE: 'POST_BUDGET_FAILURE',
	POST_CLIENT_START: 'POST_CLIENT_START',
	POST_CLIENT_SUCCESS: 'POST_CLIENT_SUCCESS',
	POST_CLIENT_FAILURE: 'POST_CLIENT_FAILURE',
	POST_ORDERITEMS_START: 'POST_ORDERITEMS_START',
	POST_ORDERITEMS_SUCCESS: 'POST_ORDERITEMS_SUCCESS',
	POST_ORDERITEMS_FAILURE: 'POST_ORDERITEMS_FAILURE',
	SET_CLIENT_DATA: 'SET_CLIENT_DATA',
	SET_OFFER_ID: 'SET_OFFER_ID',
	SET_CLIENT_ITEMS: 'SET_CLIENT_ITEMS',
	SET_PAYMENT_TYPES: 'SET_PAYMENT_TYPES',
	SET_FORM_ERRORS: 'SET_FORM_ERRORS',
	SET_ORDER_TOTAL: 'SET_ORDER_TOTAL',
	SET_SHIPPING_PRICE: 'SET_SHIPPING_PRICE',
	SET_DISCOUNT_PRICE: 'SET_DISCOUNT_PRICE',
	RESET_FORMS: 'RESET_FORMS',
};

/**
 * Data types
 */
export interface Client {
	name?: string;
	email?: string;
	phoneNumber?: string;
	cpf?: string;
}

export interface Item {
	description?: string;
	code?: string;
	quantity?: number;
	amount?: number;
}

export interface PaymentType {
	payment_method?: string;
	credit_card?: {
		soft_descriptor?: string;
		installments?: Array<object>;
		capture?: Array<object> | boolean;
		statement_descriptor?: string;
	};
	boleto?: {
		bank?: string;
		instructions?: string;
		due_at?: string;
	};
	pix?: {
		expires_at?: string;
		expires_in?: number;
	};
}

export interface OrderData {
	id?: string;
	amount?: number;
	code?: string;
	checkouts: {
		amount?: number;
		payment_url: string;
	}[];
	customer?: {
		name: string;
		email: string;
		document: string;
		address?: {
			city: string;
			state: string;
			street: string;
			neighborhood: string;
			number: string;
		};
	};
}

/**
 * State type
 */
export interface CreateOrdersState {
	readonly clientInfo: Client;
	readonly budget: Budget | undefined;
	readonly items: Item[];
	readonly paymentTypes: PaymentType[];
	readonly orderData: OrderData | undefined;
	readonly orderTotal: number;
	readonly loadingOrder: boolean;
	readonly offerId: string;
	readonly loadingBudget: boolean;
	readonly shippingPrice: number;
	readonly discountPrice: number;
	readonly importedItems: Budget | undefined;
	readonly clientBudgetData: ClientBudgetData | undefined;
	readonly loadingClient: boolean;
}
