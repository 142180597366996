import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Nav, CustomLink, Icon } from './style';

const Navigation = () => {
	const location = useLocation();

	if (location.pathname === '/pedidos/success') {
		return <></>;
	}

	return (
		<Container>
			<Nav>
				<CustomLink to="/">
					<Icon aria-label="CirclePlus" iconName="CirclePlus" /> Criar
				</CustomLink>
				<CustomLink to="/pedidos">
					<Icon aria-label="ReservationOrders" iconName="ReservationOrders" />{' '}
					Pedidos
				</CustomLink>
				{/* <CustomLink to="/pagamentos">
					<Icon aria-label="ActivateOrders" iconName="ActivateOrders" />{' '}
					Pagamentos
				</CustomLink>
				<CustomLink to="/relatorio">
					<Icon aria-label="BarChartVertical" iconName="BarChartVertical" />{' '}
					Relatório
				</CustomLink> */}
			</Nav>
		</Container>
	);
};

export default Navigation;
