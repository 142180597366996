import React, { useEffect } from 'react';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';

import authConfig from '../../common/constants/auth';

export const msalApp = new PublicClientApplication(authConfig.msalConfig);

interface authenticationProps {
	nonAuthenticated: React.ReactNode;
	children: React.ReactNode;
	isAutenticated: boolean;
	setIsAuthenticated: (newState: boolean) => void;
	setHomeAccount: (newState: string) => void;
}

const Authentication = ({
	nonAuthenticated,
	children,
	isAutenticated,
	setIsAuthenticated,
	setHomeAccount,
}: authenticationProps) => {
	const logout = async () => {
		window.localStorage.clear();
	};

	const processLogIn = (response) => {
		if (response === null) {
			getToken();
			return;
		}
		const acessotoken = `bearer  ${response.accessToken}`;
		localStorage.setItem('account', JSON.stringify(response));
		localStorage.setItem('acessotoken', acessotoken);
		setHomeAccount(response?.account);
		setIsAuthenticated(true);

		// set token header
		axios.defaults.headers.common.Authorization = acessotoken;

		return response;
	};

	const getToken = () => {
		const account = localStorage.getItem('account');

		console.log(account);

		if (!account) {
			msalApp.acquireTokenRedirect(authConfig.tokenRequest);
			return;
		}

		const localAccount = JSON.parse(account || '')?.account;
		const tokenRequest = {
			...authConfig.tokenRequest,
			account: localAccount,
		};

		return msalApp
			.acquireTokenSilent(tokenRequest)
			.then(processLogIn)
			.catch(() => {
				logout();
				return msalApp.acquireTokenRedirect(tokenRequest);
			});
	};

	const handleAuthentication = () => {
		const storagedAccount = localStorage.getItem('account');

		if (storagedAccount) {
			getToken();
		} else {
			msalApp
				.handleRedirectPromise()
				.then(processLogIn)
				.catch(() => {
					logout();
					getToken();
				});
		}
	};

	useEffect(() => {
		handleAuthentication();
	}, []);

	if (!isAutenticated) return <>{nonAuthenticated}</>;

	return <>{children}</>;
};

export default Authentication;
