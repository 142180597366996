import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

type Props = {
	smallInput?: boolean;
	showStep?: boolean;
	itemsGap?: boolean;
	hasError?: boolean;
	itemResume?: boolean;
};

export const Container = styled(Stack)<Props>`
	display: ${({ showStep }) => (showStep ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 70px;
	position: relative;
`;

export const HorizontalFlex = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const CustomText = styled.p`
	font-size: 12px;
	line-height: 22px;
	margin: 0;
`;

export const ClientInfoText = styled.p`
	padding: 0;
	margin: 0;
	font-weight: 500;
`;

export const ColumnFlex = styled.div<Props>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: ${({ itemsGap }) => (itemsGap ? '2px' : '15px')};
	gap: ${({ itemsGap }) => (itemsGap ? '15px' : '4px')};
`;

export const PaymentMethods = styled.div`
	padding-top: 20px;
	padding-bottom: 25px;
	width: 100%;
`;

export const Span = styled.span`
	font-size: 18px;
`;

export const Options = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 35px;
`;

export const Button = styled(DefaultButton)``;
export const NextStepBtn = styled(PrimaryButton)``;

export const StepControls = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-top: solid 1px rgb(243, 242, 241);
	padding-top: 15px;
`;

export const ControlsContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const LinkContainer = styled.div`
	width: 100%;
	padding-top: 10px;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
`;

export const ErrorMessage = styled.span<Props>`
	display: ${({ hasError }) => (hasError ? 'block' : 'none')};
	margin-bottom: 15px;
	margin-top: -6px;
	transition: 0.23s ease;
	font-size: 12px;
	font-weight: 400;
	color: rgb(164, 38, 44);
`;

export const Link = styled.div`
	background: #f5f3f3;
	padding: 20px;
	max-width: 500px;
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
`;

export const NormalLink = styled.a`
	text-decoration: none;
	color: #0078d4;
	border-bottom: solid 1px transparent;

	&:hover {
		border-bottom: solid 1px #0078d4;
	}
`;

export const CopyBtn = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
`;

export const Customer = styled.div`
	width: 100%;
	margin: 5px auto;
	padding: 50px 0 20px 0;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: space-between;

	@media (max-width: 780px) {
		flex-wrap: wrap;
	}
`;

export const OrderResume = styled.div<Props>`
	max-width: ${({ itemResume }) => (itemResume ? '100%' : '290px')};
	width: 100%;
`;

export const Header = styled.span`
	font-weight: 500;
	font-size: 16px;
`;

export const Info = styled.div`
	padding: 8px;
	border: solid 1px #c5c1bf;
	border-radius: 2px;
	margin-top: 10px;
`;

export const OrderTotal = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	align-self: flex-end;
	span {
		margin-right: 10px;
		font-size: 18px;
	}
`;

export const Payments = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 2px;
`;

export const PaymentsInfo = styled(Payments)`
	padding: 0;
	margin-top: 10px;
	gap: 5px;
`;

export const List = styled.div`
	padding-left: 2px;
`;

export const ItemDescription = styled.div`
	padding-right: 12px;
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	gap: 4px;
	span {
		background-color: #f5f3f3;
		font-size: 12px;
		border-radius: 6px;
		padding: 4px 8px;
		margin-left: 4px;
	}
`;

export const ItemPrice = styled.div``;

export const ItemQuantity = styled.span``;

export const Total = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: solid 1px #d4d0d0;
	padding-top: 7px;
	span {
		font-weight: 500;
	}
`;

export const DeliveryPrice = styled(HorizontalFlex)`
	margin-bottom: -10px;
`;

export const InfoTitle = styled.span`
	font-weight: 500;
`;

export const TaxesText = styled.span``;
