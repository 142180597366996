const env = window.location.hostname;
let redirectUri;

if (env === 'localhost') {
	redirectUri = 'http://localhost:3000/';
} else {
	redirectUri = `https://${env}`;
}
// PROD
const clientId = '0c64ab3e-4b5b-420c-b215-a71f23ef2d38';

// DEV
// const clientId = '1aca8276-10e5-4b4d-892d-26cefa337f16';

// // DEV-SPA
// const clientId = 'bf4d2a79-9e25-4808-b062-a8f9bf8cc4a6';

const authConfig = {
	msalConfig: {
		auth: {
			clientId,
			authority:
				'https://login.microsoftonline.com/5d0cc098-1257-4f97-be38-b99492a6f02b',
			prompt: 'consent',
			redirectUri,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false,
		},
	},
	loginRequest: {
		scopes: ['openid', 'profile', 'offline_access'],
	},
	tokenRequest: {
		scopes: ['openid', 'profile', 'offline_access'],
	},
};

export default authConfig;
