import { Reducer } from 'redux';
import { CreateOrderTypes, CreateOrdersState } from './types';

const INITIAL_STATE: CreateOrdersState = {
	clientInfo: {},
	offerId: '',
	items: [],
	paymentTypes: [],
	orderData: undefined,
	importedItems: undefined,
	loadingClient: false,
	clientBudgetData: undefined,
	orderTotal: 0,
	shippingPrice: 0,
	discountPrice: 0,
	budget: undefined,
	loadingOrder: false,
	loadingBudget: false,
};

/**
 * Dados do cliente
 */
export const setClientData: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_CLIENT_DATA:
			console.log('action.payload', action.payload);
			return {
				...state,
				clientInfo: action.payload,
			};
		case CreateOrderTypes.RESET_FORMS:
			return {
				...state,
				clientInfo: state.clientInfo,
			};
		default:
			return state;
	}
};

/**
 * ID da oferta
 */
export const setOfferId: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_OFFER_ID:
			return {
				...state,
				offerId: action.payload,
			};
		case CreateOrderTypes.RESET_FORMS:
			return {
				...state,
				offerId: state.clientInfo,
			};
		default:
			return state;
	}
};

/**
 * Items adicionados ao pedido
 */
export const orderItems: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_CLIENT_ITEMS:
			return {
				...state,
				items: action.payload,
			};
		default:
			return state;
	}
};

/**
 * Adicionar os tipos de pagamento
 */
export const paymentTypes: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_PAYMENT_TYPES:
			return {
				...state,
				paymentTypes: action.payload,
			};
		default:
			return state;
	}
};

/**
 * Capturar o total do pedido
 */
export const orderTotal: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_ORDER_TOTAL:
			return {
				...state,
				orderTotal: action.payload,
			};
		default:
			return state;
	}
};

/**
 * Capturar o valor do frete
 */
export const shippingPrice: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_SHIPPING_PRICE:
			return {
				...state,
				shippingPrice: action.payload,
			};
		default:
			return state;
	}
};

/**
 * Capturar o valor do frete
 */
export const discountPrice: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.SET_DISCOUNT_PRICE:
			return {
				...state,
				discountPrice: action.payload,
			};
		default:
			return state;
	}
};

/**
 * POST request para criar o pedido
 */
export const postOrderRequest: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.POST_ORDER_START:
			return {
				...state,
				loadingOrder: true,
			};
		case CreateOrderTypes.POST_ORDER_SUCCESS:
			return {
				...state,
				orderData: action.payload,
				loadingOrder: false,
			};
		case CreateOrderTypes.POST_ORDER_FAILURE:
			return {
				...state,
				loadingOrder: false,
			};
		default:
			return state;
	}
};

/**
 * POST para buscar orçamentos prontos
 */
export const postOrderBudget: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.POST_BUDGET_START:
			return {
				...state,
				loadingBudget: true,
			};
		case CreateOrderTypes.POST_BUDGET_SUCCESS:
			return {
				...state,
				budget: action.payload,
				loadingBudget: false,
			};
		case CreateOrderTypes.RESET_BUDGET:
			return {
				...state,
				budget: action.payload,
				loadingBudget: false,
			};
		case CreateOrderTypes.POST_BUDGET_FAILURE:
			return {
				...state,
				loadingBudget: false,
			};
		default:
			return state;
	}
};

/**
 * POST para buscar produtos
 */
export const postOrderItem: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.POST_ORDERITEMS_START:
			return {
				...state,
				loadingBudget: true,
			};
		case CreateOrderTypes.POST_BUDGET_SUCCESS:
			return {
				...state,
				importedItems: action.payload,
				loadingBudget: false,
			};
		case CreateOrderTypes.RESET_BUDGET:
			return {
				...state,
				importedItems: action.payload,
				loadingBudget: false,
			};
		case CreateOrderTypes.POST_ORDERITEMS_FAILURE:
			return {
				...state,
				loadingBudget: false,
			};
		default:
			return state;
	}
};

/**
 * POST para buscar produtos
 */
export const postClientBudgetData: Reducer<CreateOrdersState> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CreateOrderTypes.POST_CLIENT_START:
			return {
				...state,
				loadingClient: true,
			};
		case CreateOrderTypes.POST_CLIENT_SUCCESS:
			return {
				...state,
				clientBudgetData: action.payload,
				loadingClient: false,
			};

		case CreateOrderTypes.RESET_CLIENT:
			return {
				...state,
				clientBudgetData: action.payload,
				loadingClient: false,
			};
		case CreateOrderTypes.POST_CLIENT_FAILURE:
			return {
				...state,
				loadingClient: false,
			};
		default:
			return state;
	}
};
