import styled from 'styled-components';
import { SpinButton } from '@fluentui/react';

interface Props {
	hasError: boolean | undefined;
}

export const NumericField = styled(SpinButton)`
	div {
		&:nth-child(2) {
			&:after {
				border: none;
			}
		}
	}
`;

export const SpinButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: flex-end;
	gap: 5px;
	position: relative;
`;

export const ErrorTxt = styled.span<Props>`
	position: absolute;
	opacity: ${({ hasError }) => (hasError ? '1' : '0')};
	bottom: ${({ hasError }) => (hasError ? '-20px' : '0')};
	transition: 0.23s ease;
	font-size: 12px;
	font-weight: 400;
	color: rgb(164, 38, 44);
`;
