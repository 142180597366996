import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
	IComboBoxOption,
	DatePicker,
	defaultDatePickerStrings,
} from '@fluentui/react';
import {
	setCreatedSince,
	setCreatedUntil,
	setStatusFilter,
} from '../../../../../common/redux/orderList/actions';

import {
	ListHeader,
	Input,
	FilterButton,
	InputContainer,
	Select,
	DatePickerContainer,
	Date,
	ButtonContainer,
	Field,
	ClearCode,
} from './style';

interface IOrderFilters {
	loading: boolean;
	today: Date;
	status: string;
	code: string;
	hasCode: boolean;
	onFilter: (e: any, value: any) => void;
	handleOrdersList: () => void;
	setDatePicker: (picker: string, date: string) => void;
	handleDatePicker: (picker: string) => Date | undefined;
	handleClearFilters: (value: string) => void;
}

const searchIcon = { iconName: 'Search' };
const clearIcon = { iconName: 'ChromeClose' };

const OrderFilters: React.FunctionComponent<IOrderFilters> = ({
	loading,
	status,
	code,
	hasCode,
	today,
	onFilter,
	handleOrdersList,
	setDatePicker,
	handleClearFilters,
	handleDatePicker,
}) => {
	const dispatch = useDispatch();

	const options: IComboBoxOption[] = [
		{ key: '', text: 'Todos' },
		{ key: 'paid', text: 'Pago' },
		{ key: 'pending', text: 'Pendente' },
		{ key: 'canceled', text: 'Cancelado' },
		{ key: 'failed', text: 'Falha' },
	];

	const handleStatus = async (value: any) => {
		dispatch(setStatusFilter(value.key));
	};

	const onFormatDate = (date: any) => {
		return moment(date).format('DD/MM/YYYY');
	};

	const handleCreatedSince = (date: any) => {
		dispatch(setCreatedSince(moment(date).utc().format()));
		setDatePicker('created_since', moment(date).utc().format());
	};

	const handleCreatedUntil = (date: any) => {
		dispatch(setCreatedUntil(moment(date).utc().format()));
		setDatePicker('created_until', moment(date).utc().format());
	};

	const handleKeyPress = (e: any) => {
		if (e.keyCode === 13) {
			handleOrdersList();
		}
	};

	return (
		<ListHeader>
			<InputContainer>
				<Input>
					<Field
						placeholder="ID ou Código de referência do pedido no sistema da loja..."
						value={code ?? ''}
						onKeyDown={(e) => handleKeyPress(e)}
						onChange={(e, value) => onFilter(e, value)}
					/>
					{hasCode && (
						<ClearCode
							iconProps={clearIcon}
							title="Remover"
							ariaLabel="Remover"
							onClick={() => handleClearFilters('code')}
						/>
					)}
				</Input>
				<ButtonContainer>
					<FilterButton
						loading={loading}
						icon={searchIcon}
						onClick={() => handleOrdersList()}
						text="Pesquisar"
					/>
				</ButtonContainer>
			</InputContainer>
			<DatePickerContainer>
				<Select
					defaultSelectedKey=""
					selectedKey={!status ? '' : status}
					label="Status"
					options={options}
					onChange={(e, value) => handleStatus(value)}
				/>
				<Date>
					<DatePicker
						label="De:"
						placeholder="Selecione uma data"
						value={handleDatePicker('created_since')}
						formatDate={(value) => onFormatDate(value)}
						maxDate={today}
						ariaLabel="Selecione uma data"
						strings={defaultDatePickerStrings}
						onSelectDate={(value) => handleCreatedSince(value)}
					/>
				</Date>
				<Date>
					<DatePicker
						label="Até:"
						placeholder="Selecione uma data"
						ariaLabel="Selecione uma data"
						formatDate={(value) => onFormatDate(value)}
						value={handleDatePicker('created_until')}
						strings={defaultDatePickerStrings}
						maxDate={today}
						onSelectDate={(value) => handleCreatedUntil(value)}
					/>
				</Date>
			</DatePickerContainer>
		</ListHeader>
	);
};

export default OrderFilters;
