import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { IIconProps } from '@fluentui/react';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { useConst } from '@fluentui/react-hooks';
import {
	DetailsListLayoutMode,
	IColumn,
	IDetailsListProps,
	IDetailsRowStyles,
	DetailsRow,
	SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { OrderListData } from '../../types';
import DetailControls from '../../components/detailsControls';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import Status from '../../components/orderStatus';
import Pagination from '../../../../../containers/pagination';
import OrderFilters from '../../components/orderFilters';
import NothingFound from '../../components/nothingFound';
import { setOrderCode } from '../../../../../common/redux/orderList/actions';

import {
	Container,
	ListContainer,
	FilterActive,
	FiltersContainer,
	ResetBtn,
	Clear,
} from './style';
import useTimer from '../../../../../common/hooks/useTimer';

interface IOrderListProps {
	ordersList: OrderListData[];
	handlePagination: any;
	page: any;
	totalPages: number;
	loadingList: boolean;
	dates: any;
	status: any;
	code: string;
	hasCode: boolean;
	handleOrdersList: () => void;
	handleClearFilters: (status?: string) => void;
	openDetailPanel: (code: string) => void;
	setDatePicker: (picker: string, date: string) => void;
	handleDatePicker: any;
}

const clearIcon: IIconProps = { iconName: 'Delete' };

const OrdersList: React.FunctionComponent<IOrderListProps> = ({
	ordersList,
	handlePagination,
	totalPages,
	page,
	dates,
	hasCode,
	status,
	loadingList,
	code,
	handleOrdersList,
	handleClearFilters,
	openDetailPanel,
	setDatePicker,
	handleDatePicker,
}) => {
	const dispatch = useDispatch();
	const today = useConst(new Date(Date.now()));

	const { seconds } = useTimer({
		minutes: 3,
		onTimerEnd: () => handleOrdersList(),
	});

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;

		const formattedMinutes = String(minutes).padStart(2, '0');
		const formattedSeconds = String(seconds).padStart(2, '0');

		return `${formattedMinutes}:${formattedSeconds}`;
	};

	let filtersArr: any = [];

	const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
		const customStyles: Partial<IDetailsRowStyles> = {};
		if (props) {
			customStyles.cell = {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				paddingLeft: '28px',
				paddingRight: '28px',
				alignSelf: 'center',
			};

			return <DetailsRow {...props} styles={customStyles} />;
		}
		return null;
	};

	const onFilter = (
		ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		text?: any
	): void => {
		dispatch(setOrderCode(text));
	};

	const handleStatus = () => {
		if (status === 'pending') return 'Pendente';
		if (status === 'failed') return 'Falha';
		if (status === 'canceled') return 'Cancelado';
		return 'Pago';
	};

	const getOrderTotal = (data: any): number => {
		let totalValue = 0;
		if (data) {
			data.items?.map((order: any) => {
				if (order.description !== 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue += amount * order.quantity;
				}
				if (order.description === 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue -= amount;
				}
				return totalValue;
			});
		}
		return totalValue;
	};

	const columns: IColumn[] = [
		{
			key: 'column1',
			name: 'ID',
			fieldName: 'id',
			minWidth: 150,
			maxWidth: 300,
			isResizable: true,
		},
		{
			key: 'column2',
			name: 'Pedido',
			fieldName: 'code',
			minWidth: 150,
			maxWidth: 300,
			isResizable: true,
		},
		{
			key: 'column3',
			name: 'Cliente',
			fieldName: 'name',
			minWidth: 150,
			maxWidth: 300,
			isResizable: true,
			onRender: (data) => data.customer.name,
		},
		{
			key: 'column10',
			name: 'Valor',
			fieldName: 'total',
			minWidth: 140,
			maxWidth: 200,
			isResizable: true,
			onRender: (data) => formatPrice(getOrderTotal(data)),
		},
		{
			key: 'column12',
			name: 'Status',
			fieldName: 'status',
			minWidth: 150,
			maxWidth: 200,
			isResizable: true,
			onRender: (item) => <Status status={item.status} />,
		},
		{
			key: 'column11',
			name: 'Criado em',
			fieldName: 'created_at',
			minWidth: 150,
			isResizable: true,
			onRender: (data) =>
				moment(data.created_at).format('DD/MM/YYYY - hh:mm:ss'),
		},
		{
			key: 'column13',
			name: '',
			fieldName: '',
			minWidth: 50,
			maxWidth: 50,
			isResizable: true,
			onRender: (data) => (
				<DetailControls openDetailPanel={() => openDetailPanel(data.id)} />
			),
		},
	];

	const handleClearBtn = () => {
		if (status) filtersArr = [...filtersArr, status];
		if (dates.createdSince) filtersArr = [...filtersArr, dates.createdSince];
		if (dates.createdUntil) filtersArr = [...filtersArr, dates.createdUntil];

		if (filtersArr.length > 1) {
			return (
				<Clear iconProps={clearIcon} onClick={() => handleClearFilters()}>
					Limpar filtros
				</Clear>
			);
		}
	};

	return (
		<Container>
			<OrderFilters
				onFilter={onFilter}
				handleOrdersList={handleOrdersList}
				setDatePicker={setDatePicker}
				handleClearFilters={handleClearFilters}
				handleDatePicker={handleDatePicker}
				status={status}
				hasCode={hasCode}
				today={today}
				code={code}
				loading={loadingList}
			/>
			<FiltersContainer>
				{status && (
					<FilterActive>
						{handleStatus()}{' '}
						<ResetBtn onClick={() => handleClearFilters('status')}>x</ResetBtn>
					</FilterActive>
				)}
				{dates.createdSince && (
					<FilterActive>
						De: {moment(dates.createdSince).format('DD/MM/YYYY')}{' '}
						<ResetBtn onClick={() => handleClearFilters('created_since')}>
							x
						</ResetBtn>
					</FilterActive>
				)}
				{dates.createdUntil && (
					<FilterActive>
						Até: {moment(dates.createdUntil).format('DD/MM/YYYY')}{' '}
						<ResetBtn onClick={() => handleClearFilters('created_until')}>
							x
						</ResetBtn>
					</FilterActive>
				)}
				{handleClearBtn()}
			</FiltersContainer>
			<ListContainer>
				<FiltersContainer style={{ marginLeft: '26px' }}>
					<FilterActive style={{ padding: '5px 15px' }}>
						Atualização em: {formatTime(seconds)}
					</FilterActive>
				</FiltersContainer>
				<ShimmeredDetailsList
					items={ordersList}
					columns={columns}
					setKey="set"
					disableSelectionZone
					selectionMode={SelectionMode.none}
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick
					enableShimmer={loadingList}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="select row"
					onRenderRow={onRenderRow}
				/>
			</ListContainer>
			{!loadingList && ordersList.length === 0 && <NothingFound />}
			{ordersList.length > 0 && (
				<Pagination
					handlePagination={handlePagination}
					totalPages={totalPages}
					loading={loadingList}
					page={page}
				/>
			)}
		</Container>
	);
};

export default OrdersList;
