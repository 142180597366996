import { combineReducers } from 'redux';
import {
	setClientData,
	orderItems,
	paymentTypes,
	orderTotal,
	postOrderRequest,
	postOrderBudget,
	shippingPrice,
	discountPrice,
	postClientBudgetData,
} from './createOrder/reducers';
import { addOrderItem } from './editOrder/reducers';
import {
	getOrderDetail,
	getOrdersList,
	getTotalOrders,
	setOrderCode,
	setOrderDates,
	setOrderStatus,
} from './orderList/reducers';
import { getEmbeddedReport } from './report/reducers';

const combinedReducers = combineReducers({
	clientData: setClientData,
	payments: paymentTypes,
	orderResults: postOrderRequest,
	total: orderTotal,
	ordersList: getOrdersList,
	detail: getOrderDetail,
	ordersTotal: getTotalOrders,
	status: setOrderStatus,
	orderId: setOrderCode,
	dates: setOrderDates,
	embedded: getEmbeddedReport,
	orderBudget: postOrderBudget,
	shipping: shippingPrice,
	discount: discountPrice,
	clientBudget: postClientBudgetData,
	orderItems,
	addOrderItem,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === 'RESET_FORMS') {
		return combinedReducers(undefined, action);
	}

	return combinedReducers(state, action);
};

export type DataState = ReturnType<typeof rootReducer>;

export default rootReducer;
