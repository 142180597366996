import { Text } from '@fluentui/react';
import styled from 'styled-components';

export const CustomTitle = styled(Text)`
	font-size: 20px;
	font-weight: 600;
	color: rgb(50, 49, 48);
	display: inline;

	@media (max-width: 565px) {
		text-align: center;
	}
`;
