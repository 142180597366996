import React from 'react';
import { Text, IIconProps } from '@fluentui/react';
import CopyButton from '../../../../../common/ui/copyButton';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import {
	Container,
	LinkContainer,
	Button,
	Link,
	NormalLink,
	CopyBtn,
	StepControls,
	SuccessMessage,
	NextStepBtn,
	Icon,
	PaymentInfo,
} from './style';

interface IPaymentLinkProps {
	step: number | undefined;
	orderData: any;
	total: number;
	handleStep: Function;
	redirectToOrder: Function;
}

const add: IIconProps = { iconName: 'Add' };
const pageOut: IIconProps = { iconName: 'MiniExpand' };

const PaymentLink: React.FunctionComponent<IPaymentLinkProps> = ({
	step,
	orderData,
	handleStep,
	redirectToOrder,
	total,
	...props
}) => {
	return (
		<Container {...props} showStep={step === 4}>
			<SuccessMessage>
				<Icon aria-label="CheckMark" iconName="CheckMark" />
				<Text variant="xxLarge">Pedido criado com sucesso!</Text>
			</SuccessMessage>
			<PaymentInfo>
				<Text key={orderData?.id}>
					O pedido {orderData?.id} no valor de {formatPrice(total)} pode ser
					pago neste link
				</Text>
			</PaymentInfo>
			<LinkContainer>
				{orderData?.checkouts?.[0] && (
					<Link key={orderData?.id}>
						<NormalLink
							href={orderData.checkouts[0].payment_url}
							target="_blank"
						>
							{orderData.checkouts[0].payment_url}
						</NormalLink>
						<CopyBtn>
							<CopyButton copyText={orderData.checkouts[0].payment_url} />
						</CopyBtn>
					</Link>
				)}
			</LinkContainer>
			<StepControls>
				<Button
					iconProps={pageOut}
					text="Visualizar Pedido"
					onClick={() => redirectToOrder()}
				/>
				<NextStepBtn
					iconProps={add}
					text="Criar novo pedido"
					onClick={() => handleStep(0)}
				/>
			</StepControls>
		</Container>
	);
};

export default PaymentLink;
