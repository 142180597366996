import axios from 'axios';
import {
	Budget,
	ItemsBody,
	RequestData,
} from '../../pages/admin/createOrder/types';
import {
	rovalBudgetApi,
	rovalBudgetConfig,
	PagarMeApiConfig,
	PagarMeUrl,
} from '../constants/api';
import { OrderListData } from '../../pages/admin/orders/types/index';

/**
 * Criar um pedido
 * @param {object} body | JSON Body
 */
export const createOrder = (body: any): Promise<RequestData> =>
	new Promise((resolve, reject) => {
		axios
			.post(`${PagarMeUrl}/orders`, body, PagarMeApiConfig)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Listar os pedidos
 * @param {number} page | número da página
 * @param {string} code | código do pedido
 * @param {string} status | status do pedido
 * @param {Date} createdSince | data de criação inicial
 * @param {Date} createdUntil | data de criação final
 */
export const getOrders = (
	page: number,
	code: string,
	status: string,
	createdSince: any,
	createdUntil: any
): Promise<OrderListData> =>
	new Promise((resolve, reject) => {
		axios
			.get(
				`${PagarMeUrl}/orders?code=${code}&page=${page}&size=20&navigation=True&status=${status}&created_since=${createdSince}&created_until=${createdUntil}`,
				PagarMeApiConfig
			)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Listar pedido por código
 * @param {string} code | código do pedido
 */
export const getOrderByCode = (code: string): Promise<OrderListData> =>
	new Promise((resolve, reject) => {
		axios
			.get(`${PagarMeUrl}/orders/${code}`, PagarMeApiConfig)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Listar pedido por id
 * @param {string} id | código do pedido
 */
export const getOrderById = (id: string): Promise<OrderListData> =>
	new Promise((resolve, reject) => {
		axios
			.get(`${PagarMeUrl}/orders/${id}`, PagarMeApiConfig)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Listar orcamentos já prontos
 * @param {object} body | JSON body
 */
export const postRovalBudget = (body: object): Promise<Budget> =>
	new Promise((resolve, reject) => {
		axios
			.post(`${rovalBudgetApi}`, body, rovalBudgetConfig)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Adicionar um item ao pedido
 * @param {object} body | JSON Body
 */
export const addOrderItem = (orderId: string, item: ItemsBody): Promise<void> =>
	new Promise((resolve, reject) => {
		axios
			.post(`${PagarMeUrl}/orders/${orderId}/items`, item, PagarMeApiConfig)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Remover um item do pedido
 * @param {object} body | JSON Body
 */
export const removeOrderItem = (
	orderId: string,
	itemId: string
): Promise<void> =>
	new Promise((resolve, reject) => {
		axios
			.delete(
				`${PagarMeUrl}/orders/${orderId}/items/${itemId}`,
				PagarMeApiConfig
			)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});

/**
 * Adicionar um item ao pedido
 * @param {object} body | JSON Body
 */
export const editOrderItem = (
	orderId: string,
	item: ItemsBody
): Promise<void> =>
	new Promise((resolve, reject) => {
		axios
			.put(
				`${PagarMeUrl}/orders/${orderId}/items/${item.id}`,
				item,
				PagarMeApiConfig
			)
			.then(({ data }) => resolve(data))
			.catch((err) => reject(err));
	});
