import styled from 'styled-components';
import { PrimaryButton, Stack } from '@fluentui/react';

interface Props {
	active?: boolean;
	hiddenPagination?: boolean;
	loading?: any;
	inactive?: boolean;
}

export const Container = styled(Stack)<Props>`
	display: ${({ hiddenPagination }) => (hiddenPagination ? 'none' : 'flex')};
	align-items: center;
	flex-direction: row;
	gap: 10px;
	padding-top: 24px;
`;

export const Dots = styled.span`
	align-self: flex-end;
`;

export const PaginationBtn = styled(PrimaryButton)<Props>`
	&:hover {
		background: ${(props) => !props.inactive && props.theme.colors.secondary};
		border-color: ${(props) => !props.inactive && props.theme.colors.secondary};
	}
`;

export const PageButton = styled.button<Props>`
	cursor: pointer;
	width: 30px;
	height: 32px;
	background: ${(props) =>
		props.active ? props.theme.colors.primary : 'transparent'};
	display: flex;
	align-items: center;
	justify-content: center;
	border: ${({ active }) => (active ? 'solid 1px' : 'solid 1px')};
	border-color: ${(props) =>
		props.active ? props.theme.colors.primary : 'rgb(243,242,241)'};
	border-radius: 2px;
	color: ${({ active }) => (active ? '#fff' : '#3a3838')};
	pointer-events: ${({ loading }) => (loading ? 'none' : 'unset')};
	opacity: ${({ loading, active }) => (loading && !active ? '0.4' : '1')};

	&:hover {
		color: #fff;
		background: ${(props) => props.theme.colors.primary};
		border: solid 1px;
		border-color: ${(props) => props.theme.colors.primary};
	}
`;
