import React, { useEffect, useState } from 'react';
import { IIconProps } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import OrderInfoForm from '../../components/createOrderForm';
import OrderItems from '../../components/createOrderItems';
import { Budget, FormErrors, ItemInfo } from '../../types/index';
import { Container, Button, FinishOrderBtn, StepControls } from './style';
import {
	handleOrderTotal,
	handleSetItems,
} from '../../../../../common/redux/createOrder/actions';

interface IOrderProps {
	handleStep: Function;
	step: number | undefined;
	formErrors: FormErrors;
	offerId: string;
	items: any;
	shippingPrice: number;
	discountPrice: number;
	setItems: (newState: any) => void;
	addOrderItem: (itemsInfo: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	setOfferId: (newState: string) => void;
	showModal: () => void;
}

const chevronLeft: IIconProps = { iconName: 'ChevronLeftMed' };

const OrderInfo: React.FunctionComponent<IOrderProps> = ({
	handleStep,
	step,
	formErrors,
	shippingPrice,
	offerId,
	discountPrice,
	items,
	setFormErrors,
	setItems,
	setOfferId,
	addOrderItem,
	showModal,
	...props
}) => {
	const dispatch = useDispatch();
	const [itemsInfo, setItemsInfo] = useState<ItemInfo>({});
	const [sameItem, setSameItem] = useState(false);
	const [total, setTotal] = useState(0);

	const addItem = (itemsInfo: any) => {
		const newUnityPrice =
			typeof itemsInfo[0]?.amount === 'string'
				? itemsInfo[0]?.amount.replace('R$', '')
				: itemsInfo[0]?.amount;
		const hasItem = items.some((item: any) => item.code === itemsInfo[0]?.code);
		setSameItem(hasItem);
		if (!hasItem) {
			setItems((prevItems: any) => [
				...prevItems,
				{
					description: itemsInfo[0]?.description,
					code: itemsInfo[0]?.code,
					quantity: parseInt(itemsInfo[0]?.quantity),
					amount: parseInt(
						typeof newUnityPrice === 'string'
							? newUnityPrice.replace(/[,.]/g, '')
							: newUnityPrice
					),
				},
			]);
		}
	};

	const removeItem = (code?: string) => {
		if (!code) return;

		const newProducts: any[] = [];

		items.map((item: any) => {
			if (item.code !== code) newProducts.push(item);
			return newProducts;
		});

		setItems(newProducts);
	};

	const nextStep = () => {
		handleStep(2);
		getOrderTotal();
		dispatch(handleSetItems(items));
	};

	const updateQuantity = (
		id: string | undefined,
		inputValue: string | undefined
	) => {
		let value = 1;

		if (inputValue) {
			value = parseInt(inputValue);
		}

		if (items) {
			const newProducts = [...items];

			const productSelected = items.filter((item: any) => item.code === id);

			let selectedElement = { ...productSelected };

			selectedElement[0].quantity = value;

			items.filter((item: any) => {
				if (item.code === id) {
					item = selectedElement;
				}
				return item;
			});

			setItems(newProducts);
			getOrderTotal();
		}
	};

	const getOrderTotal = () => {
		let totalValue = 0;
		if (items.length > 0) {
			items.map((item: any) => {
				if (item.amount !== undefined && item.quantity !== undefined) {
					totalValue += item.amount * item.quantity;
				}
				return totalValue;
			});
		}
		const totalTaxes = totalValue + shippingPrice - discountPrice;
		const totalFormatted = parseInt(totalTaxes.toFixed());
		setTotal(totalFormatted);
		dispatch(handleOrderTotal(totalFormatted));
	};

	useEffect(() => {
		getOrderTotal();
	}, [items]);

	useEffect(() => {
		if (!sameItem) return;

		const intervalId = setInterval(() => {
			setSameItem(false);
		}, 3000);

		return () => clearInterval(intervalId);
	}, [sameItem]);

	return (
		<Container {...props} showStep={step === 1}>
			<OrderInfoForm
				itemsInfo={itemsInfo}
				showModal={showModal}
				items={items}
				sameItem={sameItem}
				setItemsInfo={setItemsInfo}
				formErrors={formErrors}
				setFormErrors={setFormErrors}
				addOrderItem={addItem}
				setSameItem={setSameItem}
			/>
			<OrderItems
				items={items}
				showModal={showModal}
				total={total}
				removeItem={removeItem}
				getOrderTotal={getOrderTotal}
				updateQuantity={updateQuantity}
				offerId={offerId}
				setOfferId={setOfferId}
			/>
			<StepControls>
				<Button
					iconProps={chevronLeft}
					text="Voltar"
					onClick={() => handleStep(0)}
				/>
				<FinishOrderBtn
					disabled={items.length === 0}
					text="Próximo"
					onClick={() => nextStep()}
				/>
			</StepControls>
		</Container>
	);
};

export default OrderInfo;
