import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import moment from 'moment';
import ReactLoading from 'react-loading';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrintComponent from '../admin/orders/containers/orderDetails/print';
import {
	Container,
	Image,
	SuccessText,
	SubText,
	Back,
	InfoHeader,
	BoldText,
	InfoContainer,
	InfoBox,
	HorizontalContent,
	InfoContent,
	InfoTitle,
	InfoText,
	PrintButton,
	ButtonsContainer,
} from './style';
import { getOrderByCode } from '../../common/services/order';
import Status from '../admin/orders/components/orderStatus';
import { formatPrice } from '../../common/utils/formatPrice';

export default function SuccessPage() {
	const [searchParams] = useSearchParams();
	const orderId = searchParams.get('order_id');

	const [loadingOrder, setLoadingOrder] = useState(false);
	const [orderDetail, setOrderDetail] = useState<any>([{}]);

	useEffect(() => {
		if (orderId) {
			loadOrder();
		}
	}, [orderId]);

	async function loadOrder() {
		setLoadingOrder(true);
		try {
			console.log('orderId', orderId);
			const response = await getOrderByCode(orderId as string);

			console.log('data', response);

			setOrderDetail(response);
		} catch (err) {
			// dismissPanel();
		}
		setLoadingOrder(false);
	}

	const details = useMemo(() => orderDetail || null, [orderDetail, orderId]);
	const chargePaid = useMemo(
		() => details?.charges?.find((c) => c.status === 'paid'),
		[details]
	);

	return (
		<Container>
			{loadingOrder ? (
				<ReactLoading type="bubbles" color="#C10230" />
			) : (
				<>
					<Image src="/images/cartao-de-credito.png" alt="Cartão de crédito" />
					<SuccessText>Sucesso!</SuccessText>

					<SubText>
						Seu pedido <strong>{details?.id}</strong> foi confirmado!
					</SubText>

					<InfoContainer>
						<InfoHeader>
							<BoldText>
								Cobrança: <strong>{chargePaid?.id}</strong>
							</BoldText>
							<Status status={chargePaid?.status} />
						</InfoHeader>
						<InfoBox>
							<HorizontalContent>
								<InfoContent>
									<InfoTitle>Valor</InfoTitle>
									<InfoText>{formatPrice(chargePaid?.amount)}</InfoText>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Forma de Pagamento</InfoTitle>
									<InfoText style={{ textTransform: 'capitalize' }}>
										{chargePaid?.payment_method}
									</InfoText>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Pago em</InfoTitle>
									<InfoText>
										{moment(chargePaid?.paid_at).format(
											'DD/MM/YYYY - hh:mm:ss'
										)}
									</InfoText>
								</InfoContent>
							</HorizontalContent>
						</InfoBox>

						<ButtonsContainer>
							{/* <Back to="/pedidos">Voltar ao site</Back> */}
							<PDFDownloadLink
								document={<PrintComponent details={details} />}
								fileName={`recibo-${details?.id}.pdf`}
							>
								{() => (
									<>
										<PrintButton>Baixar recibo</PrintButton>
									</>
								)}
							</PDFDownloadLink>
						</ButtonsContainer>
					</InfoContainer>
				</>
			)}
		</Container>
	);
}
