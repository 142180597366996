import React from 'react';
import { Container, Text } from './style';

interface IStatusProps {
	status: string;
}

const Status: React.FunctionComponent<IStatusProps> = ({ status }) => {
	const handleStatus = () => {
		if (status === 'pending') return 'Pendente';
		if (status === 'failed') return 'Falha';
		if (status === 'canceled') return 'Cancelado';
		return 'Pago';
	};

	return (
		<Container status={status}>
			<Text status={status}>{handleStatus()}</Text>
		</Container>
	);
};

export default Status;
