import React from 'react';
import { IIconProps } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { PaymentType, FormErrors } from '../../types/index';
import PixCard from '../../components/pixOption';
import CreditCard from '../../components/creditCardOption';
import BoletoCard from '../../components/boletoOption';
import CustomTitle from '../../../../../common/ui/title/index';
import { valitPaymentMethods } from '../../../../../common/utils/validations';
import { handlePaymentTypes } from '../../../../../common/redux/createOrder/actions';

import {
	Container,
	PaymentMethods,
	Options,
	Button,
	NextStepBtn,
	StepControls,
} from './style';

interface IPaymentInfoProps {
	handleStep: Function;
	step: number | undefined;
	orderTotal: number;
	paymentMethods: string[];
	formErrors: FormErrors;
	paymentType: PaymentType[] | object;
	setPaymentType: (newState: PaymentType[] | object) => void;
	setPaymentMethods: (newState: any) => void;
	setFormErrors: (newState: FormErrors) => void;
}

const chevronLeft: IIconProps = { iconName: 'ChevronLeftMed' };

const PaymentInfo: React.FunctionComponent<IPaymentInfoProps> = ({
	handleStep,
	step,
	paymentMethods,
	formErrors,
	orderTotal,
	paymentType,
	setPaymentType,
	setPaymentMethods,
	setFormErrors,
	...props
}) => {
	const dispatch = useDispatch();
	const handleNextStep = async (step: number) => {
		if (valitPaymentMethods(paymentType, setFormErrors, paymentMethods)) {
			handleStep(step);
			dispatch(handlePaymentTypes(paymentType));
		}
	};

	const handlePaymentMethods = (checked: any, value: string) => {
		if (checked && !paymentMethods.includes(value)) {
			setPaymentMethods((prevTypes: any) => [...prevTypes, value]);
		} else {
			const newMethods = paymentMethods.filter((methods) => methods !== value);
			setPaymentMethods(newMethods);
		}
	};

	const getPaymentInfo = (id: string, value: any) => {
		setPaymentType({
			...paymentType,
			[id]: value,
		});
	};

	return (
		<Container {...props} showStep={step === 2}>
			<CustomTitle text="Quais formas de pagamento serão permitidas?" />
			<PaymentMethods>
				<Options>
					<CreditCard
						paymentMethods={paymentMethods}
						orderTotal={orderTotal}
						handlePaymentMethods={handlePaymentMethods}
						setPaymentType={getPaymentInfo}
						formErrors={formErrors}
						setFormErrors={setFormErrors}
					/>
					<BoletoCard
						paymentMethods={paymentMethods}
						paymentType={paymentType}
						formErrors={formErrors}
						setPaymentType={getPaymentInfo}
						handlePaymentMethods={handlePaymentMethods}
						setFormErrors={setFormErrors}
					/>
					<PixCard
						paymentMethods={paymentMethods}
						paymentType={paymentType}
						formErrors={formErrors}
						setPaymentType={getPaymentInfo}
						handlePaymentMethods={handlePaymentMethods}
						setFormErrors={setFormErrors}
					/>
				</Options>
			</PaymentMethods>
			<StepControls>
				<Button
					iconProps={chevronLeft}
					text="Voltar"
					onClick={() => handleStep(1)}
				/>
				<NextStepBtn
					text="Próximo"
					onClick={() => handleNextStep(3)}
					disabled={paymentMethods.length === 0}
				/>
			</StepControls>
		</Container>
	);
};

export default PaymentInfo;
