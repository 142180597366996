import React, { useEffect } from 'react';
import { IIconProps, Text, SpinnerSize } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useDispatch } from 'react-redux';
import { FormErrors } from '../../types/index';
import { validClientStep } from '../../../../../common/utils/validations';
import CustomTitle from '../../../../../common/ui/title/index';
import Input from '../../../../../common/ui/input';
import { handleClientData } from '../../../../../common/redux/createOrder/actions';
import {
	Container,
	InputContainer,
	Form,
	HorizontalFlex,
	StepControls,
	Button,
	CustomSpinner,
	CpfInput,
	FormInfoText,
	TitleContainer,
} from './style';

interface IClientStepProps {
	handleStep: Function;
	step: number | undefined;
	formErrors: FormErrors;
	clientInfo: any;
	showModal: () => void;
	loading: boolean;
	setFormErrors: (newState: FormErrors) => void;
}

const chevronLeft: IIconProps = { iconName: 'ChevronLeftMed' };

const ClientInfo: React.FunctionComponent<IClientStepProps> = ({
	handleStep,
	step,
	formErrors,
	showModal,
	clientInfo,
	loading,
	setFormErrors,
	...props
}) => {
	const dispatch = useDispatch();
	const handleInfo = (fieldName: string, value: any) => {
		dispatch(
			handleClientData({
				...clientInfo,
				[fieldName]: value,
			})
		);
	};

	const checkFormErrors = (step: number) => {
		if (validClientStep(clientInfo, setFormErrors)) {
			handleStep(step);
		}
	};

	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	return (
		<Container {...props} showStep={step === 0}>
			<Form>
				<TitleContainer>
					<CustomTitle text="Para quem você deseja enviar este pagamento?" />
					<Button text="Importar cliente" onClick={() => showModal()} />
				</TitleContainer>
				<HorizontalFlex>
					<CpfInput>
						<Input
							label="CPF *"
							mask="999.999.999-99"
							error={formErrors.cpf}
							value={clientInfo.cpf ?? ''}
							errorMessage="CPF inválido"
							onFocus={() => clearFormErrors('cpf')}
							onChange={(e, value) => handleInfo('cpf', value)}
						/>
						<CustomSpinner size={SpinnerSize.small} loading={loading} />
					</CpfInput>
					<InputContainer>
						<Input
							label="Nome Completo *"
							error={formErrors.name}
							value={clientInfo.name ?? ''}
							errorMessage="Informe o nome completo"
							onFocus={() => clearFormErrors('name')}
							onChange={(e, value) => handleInfo('name', value)}
						/>
					</InputContainer>
				</HorizontalFlex>
				<HorizontalFlex>
					<InputContainer>
						<Input
							label="E-mail *"
							error={formErrors.email}
							value={clientInfo.email ?? ''}
							errorMessage="Email inválido"
							onFocus={() => clearFormErrors('email')}
							onChange={(e, value) => handleInfo('email', value)}
						/>
					</InputContainer>
					<InputContainer>
						<Input
							label="Celular"
							mask="(99) 99999-9999"
							error={formErrors.phoneNumber}
							value={clientInfo.phoneNumber ?? ''}
							errorMessage="Número inválido"
							onFocus={() => clearFormErrors('phoneNumber')}
							onChange={(e, value) => handleInfo('phoneNumber', value)}
						/>
					</InputContainer>
				</HorizontalFlex>
			</Form>
			<StepControls>
				<div />
				<PrimaryButton
					text="Próximo"
					onClick={() => checkFormErrors(1)}
					disabled={loading}
				/>
			</StepControls>
		</Container>
	);
};

export default ClientInfo;
