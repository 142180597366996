import { Dispatch } from 'redux';
import { ItemsBody } from '../../../pages/admin/createOrder/types';
import { addOrderItem, editOrderItem } from '../../services/order';
import { EditOrderTypes } from './types';

/**
 * POST Adicionar um item ao pedido
 * @param {object} body | body da requisicao POST
 */
export function addItemToOrder(item: ItemsBody, orderId: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: EditOrderTypes.ADD_ITEM_START,
			});

			const response = await addOrderItem(orderId, item);

			dispatch({
				type: EditOrderTypes.ADD_ITEM_SUCCESS,
				payload: response,
			});
		} catch (err) {
			dispatch({
				type: EditOrderTypes.ADD_ITEM_FAILURE,
			});
		}
	};
}
/**
 * PUT Editar um item do pedido
 * @param {object} body | body da requisicao POST
 */
export function editItemFromOrder(item: ItemsBody, orderId: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: EditOrderTypes.EDIT_ITEM_START,
			});

			const response = await editOrderItem(orderId, item);

			dispatch({
				type: EditOrderTypes.EDIT_ITEM_SUCCESS,
				payload: response,
			});
		} catch (err) {
			dispatch({
				type: EditOrderTypes.EDIT_ITEM_FAILURE,
			});
		}
	};
}
