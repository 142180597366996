import validator from 'validator';
import moment from 'moment';
import { validName, isValidCpf } from './inputValidations';
import { FormErrors } from '../../pages/admin/createOrder/types';

moment.locale('pt-br');

/**
 * Erros do formulario de cliente
 */
export const validClientStep = (data: any, setFormErrors: Function) => {
	let validated = true;
	let id: Partial<FormErrors> = {};

	if (!data) validated = false;

	if (!data.email && !data.phoneNumber) validated = false;
	if (!data.name) validated = false;

	if (!data.name || !validName(data.name)) {
		validated = false;
		id.name = true;
	}
	if (!data.cpf || !isValidCpf(data.cpf)) {
		validated = false;
		id.cpf = true;
	}

	if (!data.email || !validator.isEmail(data.email)) {
		validated = false;
		id.email = true;
	}
	if (
		data.phoneNumber &&
		!validator.isMobilePhone(data.phoneNumber, ['pt-BR'])
	) {
		validated = false;
		id.phoneNumber = true;
	}

	if (!validated) {
		setFormErrors(id);
	} else {
		setFormErrors({});
	}

	return validated;
};

/**
 * Erros do formulario de cliente
 */
export const validClientInfo = (
	data: any,
	items: any,
	setFormErrors: Function
) => {
	let validated = true;
	let id: Partial<FormErrors> = {};

	if (!data || items.length === 0) validated = false;

	if (!data.name) {
		validated = false;
		id.name = true;
	}
	if (!data.cpf) {
		validated = false;
		id.cpf = true;
	}

	if (!validated) {
		setFormErrors(id);
	} else {
		setFormErrors({});
	}

	return validated;
};

/**
 * Erros do formulario de itens
 */
export const validItemsInfo = (data: any, setFormErrors: Function) => {
	let validated = true;
	let id: Partial<FormErrors> = {};

	if (!data) validated = false;

	if (!data.description) {
		validated = false;
		id.description = true;
	}
	if (!data.code) {
		validated = false;
		id.code = true;
	}
	if (!data.amount) {
		validated = false;
		id.amount = true;
	}
	if (!data.quantity) {
		validated = false;
		id.quantity = true;
	}

	if (!validated) {
		setFormErrors(id);
	} else {
		setFormErrors({});
	}

	return validated;
};

/**
 * Erros dos metodos de pagamento na etapa de pagamentos
 */
export const valitPaymentMethods = (
	data: any,
	setFormErrors: Function,
	paymentMethods: any
) => {
	let date = moment(data.dueAt, 'DD-MM-YYYY').format();
	let pixDate = moment(data.expiresAt, 'DD-MM-YYYY').format();
	let validated = true;
	let id: Partial<FormErrors> = {};

	if (!data || paymentMethods.length === 0) validated = false;

	if (paymentMethods.includes('credit_card')) {
		if (!data.installments) {
			validated = false;
			id.installments = true;
		}
	}

	if (paymentMethods.includes('boleto')) {
		if (!data.dueAt || !moment(date).isAfter()) {
			validated = false;
			id.dueAt = true;
		}
	}

	if (paymentMethods.includes('pix')) {
		if (!data.expiresAt || !moment(pixDate).isAfter()) {
			validated = false;
			id.expiresAt = true;
		}
	}

	if (!validated) {
		setFormErrors(id);
	} else {
		setFormErrors({});
	}

	return validated;
};

/**
 * Id da oferta que deseja ser importada
 */
export const validOfferId = (
	offerId: string,
	setFormErrors: Function,
	dataFailed?: boolean
) => {
	let validated = true;
	let id: Partial<FormErrors> = {};

	if (dataFailed || !offerId) {
		validated = false;
		id.offerId = true;
	}

	if (!validated) {
		setFormErrors(id);
	} else {
		setFormErrors({});
	}

	return validated;
};

/**
 * Id da oferta que deseja ser importada
 * @param {string} itemId | Id do item
 * @param {any} items | Items
 */
export const isDuplicated = (items: any, itemId: string) => {
	if (!items) return false;

	let isDuplicated = false;

	items.forEach((item: any) => {
		if (item.code === itemId) isDuplicated = true;
	});

	return isDuplicated;
};
