import { Dispatch } from 'redux';
import {
	Budget,
	ClientBudgetData,
} from '../../../pages/admin/createOrder/types/index';
import { CreateOrderTypes, Client, Item, PaymentType } from './types';
import { createOrder, postRovalBudget } from '../../services/order';
import { isDuplicated, validOfferId } from '../../utils/validations';
import { FormErrors, ItemsBody } from '../../../pages/admin/createOrder/types';
import { concat } from '../../utils/concat';

/**
 * Capturar data do formulario de cliente
 */
export const handleClientData = (data: Client) => ({
	type: CreateOrderTypes.SET_CLIENT_DATA,
	payload: data,
});

/**
 * Capturar offer id
 */
export const handleOfferId = (data: string) => ({
	type: CreateOrderTypes.SET_OFFER_ID,
	payload: data,
});

/**
 * Capturar itens criados
 */
export const handleSetItems = (data: Item[]) => ({
	type: CreateOrderTypes.SET_CLIENT_ITEMS,
	payload: data,
});

/**
 * Capturar as formas de pagamento
 */
export const handlePaymentTypes = (data: PaymentType[] | object) => ({
	type: CreateOrderTypes.SET_PAYMENT_TYPES,
	payload: data,
});

/**
 * Capturar o total do pagamento
 */
export const handleOrderTotal = (data: number) => ({
	type: CreateOrderTypes.SET_ORDER_TOTAL,
	payload: data,
});

/**
 * Capturar o valor do frete
 */
export const handleShippingPrice = (data: number) => ({
	type: CreateOrderTypes.SET_SHIPPING_PRICE,
	payload: data,
});

/**
 * Capturar o valor do frete
 */
export const handleDiscountPrice = (data: number) => ({
	type: CreateOrderTypes.SET_DISCOUNT_PRICE,
	payload: data,
});

/**
 * Resetar todos os formularios
 */
export const handleResetForms = () => ({
	type: CreateOrderTypes.RESET_FORMS,
});

/**
 * Resetar todos clientes
 */
export const handleResetClient = () => ({
	type: CreateOrderTypes.RESET_CLIENT,
});

/**
 * Resetar todos os budget
 */
export const handleResetBudget = () => ({
	type: CreateOrderTypes.RESET_BUDGET,
});

/**
 * POST para criar um pedido de checkout
 * @param {object} body | body da requisicao POST
 */
export const postOrderRequest =
	(
		body: object,
		handleStep: (step: number) => void,
		setFormErrors: (newState: FormErrors) => void
	) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: CreateOrderTypes.POST_ORDER_START,
			});
			setFormErrors({
				createOrder: false,
			});
			const data = await createOrder(body);

			dispatch({
				type: CreateOrderTypes.POST_ORDER_SUCCESS,
				payload: data,
			});
			dispatch(handleShippingPrice(0));
			handleStep(4);
		} catch (e) {
			setFormErrors({
				createOrder: true,
			});
			dispatch({
				type: CreateOrderTypes.POST_ORDER_FAILURE,
			});
		}
	};

/**
 * Retorna mais items para um orçamento
 * @param {object} body | body da requisicao POST
 * @param {Function} setItems | Funcao para setar os itens do pedido
 * @param {Function} setFormErrors | Funcao para setar os erros do offer id
 * @param {Function} hideModal | Funcao para setar o modal
 * @param {object} bodyResale | body da requisicao POST
 * @param {boolean} isResale | boolean para saber se é resale
 */
export const postBudgetItems =
	(
		body: object,
		setFormErrors: (newState: FormErrors) => void,
		bodyResale: object,
		isResale?: boolean
	) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: CreateOrderTypes.POST_BUDGET_START,
			});

			let data: any;

			if (isResale) {
				data = await postRovalBudget(bodyResale);
			} else {
				data = await postRovalBudget(body);
			}

			if (data.output.length === 0) {
				validOfferId('', setFormErrors, true);
				dispatch({
					type: CreateOrderTypes.POST_BUDGET_FAILURE,
				});
				return;
			}

			dispatch({
				type: CreateOrderTypes.POST_BUDGET_SUCCESS,
				payload: data,
			});
		} catch (e) {
			validOfferId('', setFormErrors, true);
			dispatch({
				type: CreateOrderTypes.POST_BUDGET_FAILURE,
			});
		}
	};

/**
 * Retorna mais items para um orçamento
 * @param {object} body | body da requisicao POST
 * @param {Function} setFormErrors | Funcao para setar os erros do offer id
 */
export const getClientWithCPF =
	(body: object, setFormErrors: (newState: FormErrors) => void) =>
	async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: CreateOrderTypes.POST_CLIENT_START,
			});

			const data = await postRovalBudget(body);

			console.log('clientData', data);

			if (data.output.length === 0) {
				validOfferId('', setFormErrors, true);
				dispatch({
					type: CreateOrderTypes.POST_CLIENT_FAILURE,
				});
				return;
			}

			dispatch({
				type: CreateOrderTypes.POST_CLIENT_SUCCESS,
				payload: data,
			});
		} catch (e) {
			validOfferId('', setFormErrors, true);
			dispatch({
				type: CreateOrderTypes.POST_CLIENT_FAILURE,
			});
		}
	};
