import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

type Props = {
	smallInput?: boolean;
	showStep: boolean;
};

export const Container = styled(Stack)<Props>`
	display: ${({ showStep }) => (showStep ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 70px;
	position: relative;
`;

export const PaymentMethods = styled.div`
	padding-top: 25px;
	padding-bottom: 25px;
	width: 100%;
`;

export const Options = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 35px;

	@media (max-width: 865px) {
		flex-wrap: wrap;
	}
`;

export const Button = styled(DefaultButton)``;
export const NextStepBtn = styled(PrimaryButton)``;

export const StepControls = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	border-top: solid 1px rgb(243, 242, 241);
	padding-top: 15px;
`;
