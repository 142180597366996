import { TableClient, AzureSASCredential } from '@azure/data-tables';

const account = 'orcamentostorage';
const sas =
	'?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2033-06-29T02:47:49Z&st=2023-06-28T18:47:49Z&spr=https&sig=Dlnr05E6PCrZDj27TGUyj07BsX8h9DG%2F5PIPTPznCI0%3D';
const paymentTable = 'parcelamentopayment';

const tableClient = new TableClient(
	`https://${account}.table.core.windows.net`,
	paymentTable,
	new AzureSASCredential(sas)
);

export async function getTableData(): Promise<string[]> {
	let entitiesIter = tableClient.listEntities();
	let i = 1;

	let emails: string[] = [];
	for await (const entity of entitiesIter) {
		if (entity.partitionKey === 'email') {
			emails.push(entity.rowKey as string);
		}
		console.log(
			`Entity${i}: PartitionKey: ${entity.partitionKey} RowKey: ${entity.rowKey}`
		);
		i++;
	}

	return emails;
}
