import { Buffer } from 'buffer';

export const orderApi =
	'https://try.readme.io/https://api.pagar.me/core/v5/orders';
export const createTokenUrl = 'https://api.pagar.me/core/v5/tokens';

export const viaCEP = (zipCode) => {
	return `https://viacep.com.br/ws/${zipCode}/json/`;
};
export const rovalBudgetApi =
	'https://intranet.farmaciaroval.com.br/api/consulta-bd';

export const rovalBudgetConfig = {
	headers: {
		Authorization:
			'Basic cm92YWxfc2hhcmVwb2ludDpHNVM4ZWJLYllPQ3ZweWNBbmJPTVgyMDFFMjQ3djI=',
		'Content-Type': 'application/json',
	},
	auth: {
		username: 'roval_sharepoint',
		password: 'G5S8ebKbYOCvpycAnbOMX201E247v2',
	},
};

export const embeddedUrl = (reportEmbedId?: string, workspaceId?: string) => {
	let api = 'i9-dashboard-api.azurewebsites.net';
	let url = '';

	if (reportEmbedId && workspaceId) {
		url = `https://${api}/EmbedInfo/${workspaceId}/${reportEmbedId}`;
	} else {
		url = `https://${api}/EmbedInfo`;
	}

	return url;
};

// DEV
// const pagarmeKeys = {
// 	secret: 'sk_test_9wv7GrIzrhn5pjaO',
// };

// PROD
const pagarmeKeys = {
	secret: 'sk_a1NOREXUzzIo3GYq',
};

export const PagarMeUrl = 'https://try.readme.io/https://api.pagar.me/core/v5';
const base64Data = Buffer.from(`${pagarmeKeys.secret}:`).toString('base64');
export const PagarMeApiConfig = {
	headers: {
		Authorization: `Basic ${base64Data}`,
		'Content-Type': 'application/json',
	},
};
