import React from 'react';
import Stepper from 'react-stepper-horizontal';

interface IStepsProps {
	steps: number | undefined;
	theme?: string;
	handleStep: Function;
}

const StepsHeader: React.FunctionComponent<IStepsProps> = ({
	handleStep,
	steps,
	theme,
}) => {
	return (
		<Stepper
			steps={[
				{
					title: 'Cliente',
					onClick() {
						handleStep(0);
					},
				},
				{
					title: 'Pedido',
					onClick() {
						handleStep(1);
					},
				},
				{
					title: 'Pagamento',
					onClick() {
						handleStep(2);
					},
				},
				{
					title: 'Resumo',
					onClick() {
						handleStep(3);
					},
				},
			]}
			activeStep={steps === undefined ? null : steps}
			activeColor={theme === 'roval' ? '#C10230' : '#0078D4'}
			completeColor={theme === 'roval' ? '#C10230' : '#0078D4'}
			circleFontColor="#FFF"
			defaultBorderColor={theme === 'roval' ? '#C10230' : '#0078D4'}
			defaultBorderStyle={theme === 'roval' ? '#C10230' : '#0078D4'}
			completeBarColor={theme === 'roval' ? '#C10230' : '#0078D4'}
		/>
	);
};

export default StepsHeader;
