/**
 * Action types
 */
export const EditOrderTypes = {
	ADD_ITEM_START: 'ADD_ITEM_START',
	ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
	ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',
	EDIT_ITEM_START: 'EDIT_ITEM_START',
	EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
	EDIT_ITEM_FAILURE: 'EDIT_ITEM_FAILURE',
};
