import React from 'react';
import { SpinnerSize } from '@fluentui/react';
import { CustomButton, ButtonText, CustomSpinner, CheckIcon } from './style';

interface IButtonProps {
	loading?: boolean;
	text?: string;
	icon?: any;
	isAdded?: boolean;
	onClick: Function;
}

const Button: React.FunctionComponent<IButtonProps> = ({
	loading,
	text,
	icon,
	isAdded,
	onClick,
}) => {
	return (
		<CustomButton
			disabled={loading || isAdded}
			iconProps={icon}
			onClick={() => onClick()}
		>
			{loading && <CustomSpinner size={SpinnerSize.small} />}
			{isAdded && <CheckIcon iconName="CheckMark" />}
			<ButtonText isLoading={loading || isAdded}>{text}</ButtonText>
		</CustomButton>
	);
};

export default Button;
