import React from 'react';
import { IIconProps } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { Container, Icon } from './style';

const detailsIcon: IIconProps = { iconName: 'FollowUser' };

interface IDetailsControls {
	openDetailPanel: () => void;
}

const DetailControls: React.FunctionComponent<IDetailsControls> = ({
	openDetailPanel,
}) => {
	const tooltipId = useId('tooltip');
	const calloutProps = { gapSpace: 0 };

	return (
		<Container>
			<TooltipHost
				content="Detalhes"
				id={tooltipId}
				calloutProps={calloutProps}
				setAriaDescribedBy={false}
			>
				<Icon
					iconProps={detailsIcon}
					aria-label="FollowUser"
					onClick={() => openDetailPanel()}
				/>
			</TooltipHost>
		</Container>
	);
};

export default DetailControls;
