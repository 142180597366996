import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	IconButton,
	IIconProps,
	Modal,
	PanelType,
	PrimaryButton,
	Spinner,
	SpinnerSize,
} from '@fluentui/react';
import moment from 'moment';

import { PDFDownloadLink } from '@react-pdf/renderer';

import { useBoolean } from '@fluentui/react-hooks';
import Status from '../../components/orderStatus';
import { formatPrice } from '../../../../../common/utils/formatPrice';
import { cpfMask } from '../../../../../common/utils/masks';
import {
	DetailsPanel,
	InfoHeader,
	BoldText,
	Text,
	InfoBox,
	InfoContainer,
	BoxTitle,
	InfoContent,
	InfoTitle,
	HorizontalHeader,
	HorizontalContent,
	HeaderCode,
	ChargeContainer,
	HeaderInfoContainer,
	DeliveryValue,
	TaxesContainer,
	HeaderButton,
} from './style';
import OrderInfo from '../../../createOrder/containers/orderInfo';
import {
	FormErrors,
	Item,
	ItemInfo,
	ItemsBody,
} from '../../../createOrder/types';
import { DataState } from '../../../../../common/redux/rootReducer';
import OrderInfoForm from '../../../createOrder/components/createOrderForm';
import {
	addItemToOrder,
	editItemFromOrder,
} from '../../../../../common/redux/editOrder/actions';
import { getOrderDetail } from '../../../../../common/redux/orderList/actions';
import { GetOrdersState } from '../../../../../common/redux/orderList/types';
import {
	getOrderByCode,
	removeOrderItem,
} from '../../../../../common/services/order';
import {
	DeleteButton,
	DeleteButtonContainer,
	EditButton,
} from '../../../createOrder/components/createOrderItems/style';
import {
	HorizontalFlex,
	ModalContainer,
	ModalContent,
	ModalHeader,
} from '../../../createOrder/components/modal/style';
import Title from '../../../../../common/ui/title';
import PrintOrder from '../printOrder';
import PrintComponent from './print';

import ModalItem from '../../../../admin/createOrder/components/modal';
import {
	handleResetBudget,
	postBudgetItems,
} from '../../../../../common/redux/createOrder/actions';
import {
	budgeItemsBody,
	resaleItemsBody,
} from '../../../../../common/utils/formatted';

const deleteIcon: IIconProps = { iconName: 'Delete' };
const editIcon: IIconProps = { iconName: 'Edit' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IOrderDetailProps {
	isOpen: boolean;
	dismissPanel: () => void;
	orderId: string;
}

const OrderDetails: React.FunctionComponent<IOrderDetailProps> = ({
	isOpen,
	dismissPanel,
	orderId,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, error, orderData } = useSelector(
		(state: DataState) => state.addOrderItem
	);

	const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
		useBoolean(false);

	const [editItems, setEditItems] = useState(false);
	const [items, setItems] = useState<ItemsBody[]>([]);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [itemsInfo, setItemsInfo] = useState<ItemInfo>({});

	const [orderDetail, setOrderDetail] = useState<any>([{}]);
	const [loadingOrder, setLoadingOrder] = useState(false);

	const [isResale, setIsResale] = useState<boolean | undefined>(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [itemToRemove, setItemToRemove] = useState<any>();
	const [loadingRemoveItem, setLoadingRemoveItem] = useState(false);
	const [budgetItemId, setBudgetItemId] = useState<string | undefined>('');

	useEffect(() => {
		if (orderId) {
			loadOrder();
		}
		if (!isOpen) {
			setEditItems(false);
		}
	}, [isOpen, modalOpen, orderId]);

	async function loadOrder() {
		setLoadingOrder(true);
		try {
			console.log('orderId', orderId);
			const response = await getOrderByCode(orderId);

			console.log('data', response);

			setOrderDetail(response);
		} catch (err) {
			dismissPanel();
		}
		setLoadingOrder(false);
	}

	const {
		clientData,
		orderItems,
		payments,
		total,
		orderResults,
		orderBudget,
		shipping,
		discount,
		clientBudget,
	} = useSelector((state: DataState) => state);

	// const { detail: orderDetail } = useSelector((state: DataState) => state);

	const details = useMemo(() => orderDetail || null, [orderDetail, orderId]);
	console.log('orderDetail, orderId', orderDetail, orderId);

	const itemsNoDelivery = details?.items?.filter(
		(item: any) =>
			item.description !== 'Frete' && item.description !== 'Desconto'
	);
	const deliveryValue = details?.items?.filter(
		(item: any) => item.description === 'Frete'
	);
	const discountValue = details?.items?.filter(
		(item: any) => item.description === 'Desconto'
	);

	const getTotalWithDiscount = (): number => {
		let totalValue = 0;
		if (details) {
			details?.items?.map((order: any) => {
				if (order.description !== 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue += amount * order.quantity;
				}
				if (order.description === 'Desconto') {
					const amount = parseInt(order.amount);
					totalValue -= amount;
				}
				return totalValue;
			});
		}
		return totalValue;
	};

	const getTotal = (): number => {
		let totalValue = 0;
		if (details?.items.length > 0) {
			details?.items?.map((order: any) => {
				if (order.description !== 'Desconto' && order.description !== 'Frete') {
					const amount = parseInt(order.amount);
					totalValue += amount * order.quantity;
				}
				return totalValue;
			});
		}
		return totalValue;
	};

	const handlePanelWidth = () => {
		const width = document.body.clientWidth;

		if (width < 1380) {
			return '70%';
		}
		if (width < 1600) {
			return '65%';
		}
		return '45%';
	};

	const checkExistItem = (itemId: string): boolean => {
		if (items.length === 0) return false;
		let alreadyAdded = false;

		items.forEach((item: any) => {
			if (item.code === itemId) alreadyAdded = true;
		});

		return alreadyAdded;
	};

	const addOrderItemModal = async (itemsInfo: any) => {
		let newAmount = 0;
		let newItemId = itemsInfo.CODIGO.toString();
		let itemBody: ItemsBody = {};

		if (isResale) {
			const parsedValue = parseFloat(itemsInfo.PRECO).toFixed(2);
			newAmount = parseInt(parsedValue.toString().replace(/[,.]/g, ''));
		} else {
			newAmount = parseInt(itemsInfo.PRECO.replace(/[,.]/g, ''));
		}

		itemBody = {
			description: `${itemsInfo.NOME}`,
			quantity: itemsInfo.QUANTIDADE ?? 1,
			code: newItemId,
			amount:
				itemsInfo.QUANTIDADE && itemsInfo.QUANTIDADE > 1
					? newAmount / itemsInfo.QUANTIDADE
					: newAmount,
		};

		// let budgetItems: Item[] = [];

		// if (checkExistItem(newItemId)) {
		// 	budgetItems = items.map((item: any) => {
		// 		if (newItemId === item.code) {
		// 			return {
		// 				...item,
		// 				quantity: (item.quantity += 1),
		// 			};
		// 		}
		// 		return item;
		// 	});
		// } else {
		// 	budgetItems.push(...items, itemBody);
		// }

		await dispatch<any>(addItemToOrder(itemBody, details?.id));
		loadOrder();
		exitModal();
	};

	const addItem = async (itemsInfo: any) => {
		const itemInfo = itemsInfo[0] ? itemsInfo[0] : itemsInfo;
		console.log(itemInfo);

		const newUnityPrice =
			typeof itemInfo?.amount === 'string'
				? itemInfo?.amount.replace('R$', '')
				: itemInfo?.amount;

		const item = {
			description: itemInfo?.description,
			code: itemInfo?.code,
			quantity: parseInt(itemInfo?.quantity),
			amount: parseInt(
				typeof newUnityPrice === 'string'
					? newUnityPrice.replace(/[,.]/g, '')
					: newUnityPrice
			),
		};

		try {
			if (itemInfo?.id) {
				await dispatch<any>(
					editItemFromOrder({ ...item, id: itemInfo?.id }, details?.id)
				);
			} else {
				await dispatch<any>(addItemToOrder(item, details?.id));
			}

			loadOrder();
		} catch (err) {
			// TODO: Tratar erro
		}
	};

	async function removeItem() {
		setLoadingRemoveItem(true);
		try {
			const response = await removeOrderItem(details.id, itemToRemove.id);
			loadOrder();
			setItemToRemove(undefined);
			setModalOpen(false);
		} catch (err) {
			// TODO: notificar erro
		}
		setLoadingRemoveItem(false);
	}

	const exitModal = () => {
		hideModal();
		if (orderBudget.budget) {
			dispatch(handleResetBudget());
		}
	};

	const handleResale = (value: boolean | undefined) => {
		if (orderBudget.budget) {
			dispatch(handleResetBudget());
		}
		setIsResale(value);
	};

	const getBudgetItems = async () => {
		await dispatch<any>(
			postBudgetItems(
				budgeItemsBody(budgetItemId),
				setFormErrors,
				resaleItemsBody(budgetItemId),
				isResale
			)
		);
	};

	const handleBudgetItemId = (value: string) => {
		setBudgetItemId(value);
	};

	return (
		<>
			<ModalContainer
				isOpen={modalOpen}
				isBlocking={false}
				onDismiss={() => setModalOpen(false)}
			>
				<ModalHeader>
					<IconButton
						iconProps={cancelIcon}
						ariaLabel="Close popup modal"
						onClick={() => setModalOpen(false)}
					/>
				</ModalHeader>
				<ModalContent>
					<Title text="Tem certeza que deseja remover o item do pedido?" />
				</ModalContent>
				<HorizontalFlex
					style={{
						maxWidth: '100%',
						width: '100%',
						borderTop: '1px solid black',
						borderBottom: '1px solid black',
					}}
				>
					<HorizontalContent style={{ width: '60%' }} key={itemToRemove?.id}>
						<InfoContent>
							<InfoTitle>Código</InfoTitle>
							<Text itemCode>{itemToRemove?.code}</Text>
						</InfoContent>
						<InfoContent>
							<InfoTitle>Nome</InfoTitle>
							<Text itemName>{itemToRemove?.description}</Text>
						</InfoContent>
						<InfoContent>
							<InfoTitle>Quantidade</InfoTitle>
							<Text>{itemToRemove?.quantity}</Text>
						</InfoContent>
						<InfoContent>
							<InfoTitle>Valor unitário</InfoTitle>
							<Text>{formatPrice(itemToRemove?.amount)}</Text>
						</InfoContent>
					</HorizontalContent>
				</HorizontalFlex>
				<HorizontalFlex
					style={{
						maxWidth: '100%',
						width: '100%',
					}}
				>
					<HorizontalContent key={itemToRemove?.id}>
						{loadingRemoveItem ? (
							<Spinner />
						) : (
							<PrimaryButton text="Remover" onClick={() => removeItem()} />
						)}
					</HorizontalContent>
				</HorizontalFlex>
			</ModalContainer>

			<ModalItem
				title="Qual produto você gostaria de importar?"
				isModalOpen={isModalOpen}
				hideModal={exitModal}
				clientModal={false}
				getClientBudgetData={() => {}}
				loadingClient={clientBudget.loadingClient}
				clientData={clientBudget.clientBudgetData}
				isResale={isResale}
				formErrors={formErrors}
				loading={orderBudget.loadingBudget}
				items={items}
				handleBudgetItemId={handleBudgetItemId}
				clientInfo={clientData.clientInfo}
				handleClientCpf={() => {}}
				getBudgetItems={getBudgetItems}
				budgetItems={orderBudget.budget}
				setIsResale={handleResale}
				addOrderItem={addOrderItemModal}
				setFormErrors={setFormErrors}
			/>

			<DetailsPanel
				headerText="Detalhes do Pedido"
				isOpen={isOpen}
				customWidth={handlePanelWidth()}
				onDismiss={dismissPanel}
				closeButtonAriaLabel="Close"
				type={PanelType.custom}
				onOuterClick={() => {}}
			>
				{loadingOrder && !details ? (
					<Spinner size={SpinnerSize.large} />
				) : (
					<>
						<InfoHeader>
							<HeaderInfoContainer>
								<BoldText>Pedido: </BoldText>
								<Text>{details?.id}</Text>
							</HeaderInfoContainer>
							{details && (
								<PDFDownloadLink
									document={<PrintComponent details={details} />}
									fileName="pedido.pdf"
								>
									{() => (
										<>
											<PrimaryButton text="Imprimir" />
										</>
									)}
								</PDFDownloadLink>
							)}
						</InfoHeader>
						<InfoContainer>
							<InfoBox orderBox>
								<BoxTitle>Status</BoxTitle> <Status status={details?.status} />
							</InfoBox>
							<InfoBox orderBox>
								<BoxTitle>Valor </BoxTitle>
								<BoldText noMargin>
									{formatPrice(getTotalWithDiscount())}
								</BoldText>
							</InfoBox>
							<InfoBox orderBox>
								<BoxTitle>Criado Em</BoxTitle>
								<BoldText noMargin>
									{moment(details?.created_at).format('DD/MM/YYYY - hh:mm:ss')}
								</BoldText>
							</InfoBox>
						</InfoContainer>
						<InfoHeader>
							<BoldText>Informações</BoldText>
						</InfoHeader>
						<InfoContainer>
							<InfoBox>
								<BoxTitle>Dados Gerais</BoxTitle>
								<InfoContent style={{ marginTop: '20px' }}>
									<InfoTitle>ID</InfoTitle>
									<Text>{details?.id}</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Código do Pedido</InfoTitle>
									<Text>{details?.code}</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Fechado</InfoTitle>
									<Text>{details?.closed ? 'SIM' : 'NÃO'}</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Fechado em</InfoTitle>
									<Text>
										{details?.closed
											? moment(details?.closed_at).format(
													'DD/MM/YYYY - hh:mm:ss'
											  )
											: ''}
									</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Atualizado em</InfoTitle>
									<Text>
										{moment(details?.updated_at).format(
											'DD/MM/YYYY - hh:mm:ss'
										)}
									</Text>
								</InfoContent>
								{details?.checkouts && (
									<InfoContent>
										<InfoTitle>Link de cobrança</InfoTitle>
										<a
											href={details?.checkouts[0].payment_url}
											target="_blank"
											rel="noreferrer"
										>
											{details?.checkouts[0].payment_url}
										</a>
									</InfoContent>
								)}
							</InfoBox>
							<InfoBox>
								<BoxTitle>Cliente</BoxTitle>
								<InfoContent style={{ marginTop: '20px' }}>
									<InfoTitle>Nome</InfoTitle>
									<Text>{details?.customer?.name}</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Email</InfoTitle>
									<Text>{details?.customer?.email}</Text>
								</InfoContent>
								<InfoContent>
									<InfoTitle>Cpf</InfoTitle>
									<Text>{cpfMask(details?.customer?.document)}</Text>
								</InfoContent>
								{details?.customer?.phones.mobile_phone && (
									<InfoContent>
										<InfoTitle>Celular</InfoTitle>
										<Text>
											{details?.customer?.phones.mobile_phone.area_code}{' '}
											{details?.customer?.phones.mobile_phone.number}
										</Text>
									</InfoContent>
								)}
							</InfoBox>
						</InfoContainer>
						{details?.charges && (
							<>
								<InfoHeader>
									<BoldText>Cobranças</BoldText>
								</InfoHeader>
								<InfoContainer>
									<InfoBox>
										{details?.charges?.map((charges: any) => (
											<ChargeContainer key={charges.id}>
												<HorizontalHeader>
													<HeaderCode>
														<BoldText>ID: </BoldText>
														<Text>{charges.id}</Text>
													</HeaderCode>{' '}
													<Status status={charges.status} />
												</HorizontalHeader>
												<HorizontalContent>
													<InfoContent>
														<InfoTitle>Valor</InfoTitle>
														<Text>{formatPrice(charges.amount)}</Text>
													</InfoContent>
													<InfoContent>
														<InfoTitle>Forma de Pagamento</InfoTitle>
														<Text style={{ textTransform: 'capitalize' }}>
															{charges.payment_method}
														</Text>
													</InfoContent>
													<InfoContent>
														<InfoTitle>Criado em</InfoTitle>
														<Text>
															{moment(charges.created_at).format(
																'DD/MM/YYYY - hh:mm:ss'
															)}
														</Text>
													</InfoContent>
												</HorizontalContent>
											</ChargeContainer>
										))}
									</InfoBox>
								</InfoContainer>
							</>
						)}
						{editItems && (
							<OrderInfoForm
								editOrder={{
									edit: true,
									loading,
								}}
								itemsInfo={itemsInfo}
								showModal={showModal}
								items={items}
								sameItem={false}
								setItemsInfo={setItemsInfo}
								formErrors={formErrors}
								setFormErrors={setFormErrors}
								addOrderItem={addItem}
								setSameItem={() => {}}
							/>
						)}
						{details?.items && (
							<>
								<HeaderButton>
									<div />
									<PrimaryButton
										disabled={details.closed}
										style={
											editItems
												? { backgroundColor: 'white', color: 'black' }
												: {}
										}
										text={
											details.closed
												? 'Pedido fechado'
												: editItems
												? 'Cancelar'
												: 'Editar itens'
										}
										onClick={() => setEditItems(!editItems)}
									/>
								</HeaderButton>
								<InfoHeader>
									<BoldText>Itens</BoldText>
									<TaxesContainer>
										<HeaderInfoContainer>
											<BoldText>Total: </BoldText>
											<DeliveryValue>{formatPrice(getTotal())}</DeliveryValue>
										</HeaderInfoContainer>
										{discountValue[0]?.amount && (
											<HeaderInfoContainer>
												<BoldText>Desconto: </BoldText>
												<DeliveryValue>
													{formatPrice(discountValue[0]?.amount)}
												</DeliveryValue>
											</HeaderInfoContainer>
										)}
										{deliveryValue[0]?.amount && (
											<HeaderInfoContainer>
												<BoldText>Frete: </BoldText>
												<DeliveryValue>
													{formatPrice(deliveryValue[0]?.amount)}
												</DeliveryValue>
											</HeaderInfoContainer>
										)}
									</TaxesContainer>
								</InfoHeader>
								<InfoContainer>
									<InfoBox>
										{itemsNoDelivery.map((item: any) => (
											<HorizontalContent key={item.id}>
												<InfoContent>
													<InfoTitle>Código</InfoTitle>
													<Text itemCode>{item.code}</Text>
												</InfoContent>
												<InfoContent>
													<InfoTitle>Nome</InfoTitle>
													<Text itemName>{item.description}</Text>
												</InfoContent>
												<InfoContent>
													<InfoTitle>Quantidade</InfoTitle>
													<Text>{item.quantity}</Text>
												</InfoContent>
												<InfoContent>
													<InfoTitle>Valor unitário</InfoTitle>
													<Text>{formatPrice(item.amount)}</Text>
												</InfoContent>
												{editItems && (
													<>
														<InfoContent>
															<EditButton
																iconProps={editIcon}
																title="Editar"
																ariaLabel="Editar"
																onClick={() => {
																	setItemsInfo({
																		code: item.code,
																		description: item.description,
																		amount: item.amount,
																		quantity: item.quantity,
																		id: item.id,
																	});
																}}
															/>
														</InfoContent>
														<InfoContent>
															<DeleteButton
																iconProps={deleteIcon}
																title="Remover"
																ariaLabel="Remover"
																onClick={() => {
																	setItemToRemove(item);
																	setModalOpen(true);
																}}
															/>
														</InfoContent>
													</>
												)}
											</HorizontalContent>
										))}
									</InfoBox>
								</InfoContainer>
							</>
						)}
					</>
				)}
			</DetailsPanel>
		</>
	);
};

export default OrderDetails;
