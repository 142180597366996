import styled from 'styled-components';
import { Stack } from '@fluentui/react';
import {
	DefaultButton,
	PrimaryButton,
	ActionButton,
} from '@fluentui/react/lib/Button';
import { FontIcon } from '@fluentui/react/lib/Icon';

type Props = {
	smallInput?: boolean;
	showStep?: boolean;
	itemsGap?: boolean;
};

export const Container = styled(Stack)<Props>`
	display: ${({ showStep }) => (showStep ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 60px 12px;
	position: relative;
`;

export const PaymentMethods = styled.div`
	padding-top: 20px;
	padding-bottom: 25px;
	width: 100%;
`;

export const Span = styled.span`
	font-size: 18px;
`;

export const Options = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 35px;
`;

export const Button = styled(DefaultButton)``;
export const NextStepBtn = styled(PrimaryButton)``;

export const ShareButtons = styled.div`
	max-width: 540px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 0;
`;

export const StepControls = styled(ShareButtons)`
	max-width: 400px;
	padding-top: 25px;
`;

export const LinkContainer = styled.div`
	width: 100%;
	padding-top: 10px;
	display: flex;
	justify-content: center;
`;

export const Link = styled.div`
	background: #f5f3f3;
	border-radius: 2px;
	padding: 14px;
	max-width: 500px;
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
`;

export const NormalLink = styled.a`
	text-decoration: none;
	color: #0078d4;
	border-bottom: solid 1px transparent;

	&:hover {
		border-bottom: solid 1px #0078d4;
	}
`;

export const CopyBtn = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
`;

export const SuccessMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Icon = styled(FontIcon)`
	font-size: 24px;
	color: #fff;
	background: #39cc6a;
	padding: 8px;
	border-radius: 50%;
	margin-bottom: 10px;
`;

export const PaymentInfo = styled.div`
	padding-top: 25px;
	max-width: 500px;
	width: 100%;
	text-align: center;
`;

export const ShareButton = styled(ActionButton)``;
