import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import usePagination from '../../../common/hooks/usePagination';
import {
	getOrderDetail,
	getOrdersList,
	setOrderCode,
	clearCreatedSince,
	clearCreatedUntil,
	clearStatusFilter,
	getSpecificOrder,
} from '../../../common/redux/orderList/actions';
import { DataState } from '../../../common/redux/rootReducer';
import OrderDetails from './containers/orderDetails';
import OrdersList from './containers/ordersList';
import { Container } from './style';

const Orders: React.FunctionComponent = () => {
	const [sinceDate, setSinceDate] = useState<string | undefined>();
	const [untilDate, setUntilDate] = useState<string | undefined>();
	const [hasCode, setHasCode] = useState(false);
	const [idOrderDetail, setIdOrderDetail] = useState<string>();
	const [isOpen, setIsOpen] = useState(false);

	const { ordersList, ordersTotal, orderId, dates, status, detail } =
		useSelector((state: DataState) => state);
	// const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
	// 	useBoolean(false);
	const [pageNumber, handlePagination] = usePagination();
	const totalPages = Math.ceil(ordersTotal.totalOrders / 20);
	const dispatch = useDispatch();

	const getOrderById = async (id: string) => {
		await dispatch<any>(getSpecificOrder(id));
	};

	const handleOrdersList = async (code?: string) => {
		if (orderId?.code?.includes('or_')) {
			getOrderById(orderId.code);
		} else {
			await dispatch<any>(
				getOrdersList(
					orderId.code,
					pageNumber,
					status.status,
					dates.createdSince,
					dates.createdUntil
				)
			);
		}

		if (orderId.code) {
			setHasCode(true);
		} else {
			setHasCode(false);
		}
	};

	const openDetailPanel = async (code: string) => {
		setIdOrderDetail(code);
		setIsOpen(true);
	};

	const setDatePicker = (pick: string, date: string) => {
		if (pick === 'created_since') {
			setSinceDate(date);
		} else if (pick === 'created_until') {
			setUntilDate(date);
		}
	};

	const handleDatePicker = (pick: string) => {
		if (pick === 'created_since') {
			return sinceDate;
		}
		if (pick === 'created_until') {
			return untilDate;
		}
	};

	const handleClearFilters = async (filter?: string) => {
		if (filter === 'status') {
			dispatch(clearStatusFilter());
			return;
		}

		if (filter === 'created_since') {
			dispatch(clearCreatedSince());
			setSinceDate(undefined);
			return;
		}

		if (filter === 'created_until') {
			dispatch(clearCreatedUntil());
			setUntilDate(undefined);
			return;
		}

		if (filter === 'code') {
			dispatch(setOrderCode(''));
			setHasCode(false);
			await dispatch<any>(
				getOrdersList(
					'',
					pageNumber,
					status.status,
					dates.createdSince,
					dates.createdUntil
				)
			);
			return;
		}

		dispatch(clearStatusFilter());
		dispatch(clearCreatedSince());
		dispatch(clearCreatedUntil());
		setSinceDate(undefined);
		setUntilDate(undefined);
	};

	useEffect(() => {
		if (!isOpen) {
			handleOrdersList();
		}
	}, [
		pageNumber,
		status.status,
		dates.createdSince,
		dates.createdUntil,
		isOpen,
	]);

	return (
		<Container>
			<OrdersList
				totalPages={totalPages}
				dates={dates}
				handlePagination={handlePagination}
				page={pageNumber}
				ordersList={ordersList.orders}
				loadingList={ordersList.loadingList}
				status={status.status}
				code={orderId.code}
				hasCode={hasCode}
				handleOrdersList={handleOrdersList}
				handleClearFilters={handleClearFilters}
				openDetailPanel={openDetailPanel}
				handleDatePicker={handleDatePicker}
				setDatePicker={setDatePicker}
			/>
			<OrderDetails
				orderId={idOrderDetail as string}
				isOpen={isOpen}
				// orderDetail={detail.orderDetail}
				dismissPanel={() => setIsOpen(false)}
			/>
		</Container>
	);
};

export default Orders;
