import styled from 'styled-components';
import { Stack } from '@fluentui/react';

interface Props {
	status: string;
}

export const Container = styled(Stack)<Props>`
	color: #fff;
	min-width: 70px;
	border-radius: 2px;
	max-width: 70px;
	display: flex;
	align-items: center;
	padding: 4px;
	font-weight: 400;
	background: ${({ status }) => {
		if (status === 'pending') return '#fcc631dd';
		if (status === 'canceled') return '#f74b4b';
		if (status === 'failed') return '#f74b4b';
		return '#98c465d8';
	}};
`;

export const Text = styled.span<Props>`
	color: ${({ status }) => {
		if (status === 'pending') return '#614d16dd';
		if (status === 'canceled') return '#fff';
		if (status === 'failed') return '#fff';
		return '#2d4214d4';
	}};
	font-weight: 500;
`;
