import React from 'react';
import { Text } from '@fluentui/react';
import Moment from 'moment';
import { FormErrors } from '../../types';

import {
	Container,
	CardHeader,
	HeaderText,
	Icon,
	CheckboxButton,
	CardBody,
	ValidDate,
	Field,
} from './style';

interface IBoletoProps {
	handlePaymentMethods: (checked: any, newState: string) => void;
	setPaymentType: (id: string, value: any) => void;
	setFormErrors: (newState: FormErrors) => void;
	formErrors: FormErrors;
	paymentType: any;
	paymentMethods: string[];
}

const BoletoCard: React.FunctionComponent<IBoletoProps> = ({
	handlePaymentMethods,
	setPaymentType,
	setFormErrors,
	formErrors,
	paymentType,
	paymentMethods,
}) => {
	let hasBoleto = paymentMethods && paymentMethods.includes('boleto');

	const clearFormErrors = (id: string) => {
		setFormErrors({
			...formErrors,
			[id]: false,
		});
	};

	return (
		<Container hasBoleto={hasBoleto}>
			<CardHeader>
				<CheckboxButton
					checked={hasBoleto}
					onChange={(e, value) => handlePaymentMethods(value, 'boleto')}
				/>
				<HeaderText>
					<Icon aria-label="QRCode" iconName="QRCode" />
					<Text>Boleto</Text>
				</HeaderText>
			</CardHeader>
			<CardBody>
				<ValidDate>
					<Text>Validade (*)</Text>
					<Field
						disabled={!hasBoleto}
						mask="99/99/9999"
						placeholder="dd/mm/aaaa"
						value={paymentType?.dueAt ?? ''}
						error={formErrors.dueAt}
						errorMessage="Data inválida"
						onFocus={() => clearFormErrors('dueAt')}
						onChange={(e, value) => setPaymentType('dueAt', value)}
					/>
					<Text variant="small">
						Deve ser maior que a expiração do link (
						{Moment().format('DD/MM/YYYY')})
					</Text>
				</ValidDate>
			</CardBody>
		</Container>
	);
};

export default BoletoCard;
