import React, { useState, useEffect } from 'react';
import { SpinnerSize } from '@fluentui/react';
import { currencyMask } from '../../utils/masks';
import {
	Field,
	MaskedInput,
	CurrencyField,
	Label,
	CurrencyContainer,
	ErrorTxt,
} from './style';

interface IInputProps {
	onChange?: (e?: any, value?: any) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	label?: string;
	placeholder?: string;
	mask?: string;
	errorMessage?: string;
	id?: string;
	error?: boolean;
	disabled?: boolean;
	maskType?: string;
	value?: string;
	defaultValue?: string;
}

const Input: React.FunctionComponent<IInputProps> = ({
	onChange,
	onFocus,
	onBlur,
	label,
	placeholder,
	id,
	errorMessage,
	error,
	mask,
	maskType,
	value,
	disabled,
	defaultValue,
}) => {
	const [errorText, setErrorText] = useState<string | undefined>('');
	// const [value, setValue] = useState<string | undefined>('');
	const handleErrorText = () => {
		if (error) setErrorText(errorMessage);
		else setErrorText('');
	};

	useEffect(() => {
		handleErrorText();
	}, [error]);

	// const handleKeyUp = useCallback(
	//   (e: any) => {
	//     if (maskType === 'currency') {
	//       currency(e);
	//     }
	//   },
	//   [maskType]
	// );

	const handleField = () => {
		if (maskType === 'currency') {
			return (
				<CurrencyContainer>
					<Label>{label}</Label>
					<CurrencyField
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						value={value === '0' ? '' : value}
						placeholder="R$ 0,00"
						// defaultValue={defaultValue}
						onInput={(value) => currencyMask(value)}
						disabled={disabled}
					/>
					<ErrorTxt hasError={error}>{errorText}</ErrorTxt>
				</CurrencyContainer>
			);
		}
		return mask ? (
			<MaskedInput
				mask={mask}
				placeholder={placeholder}
				label={label}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
				value={value}
				onFocus={onFocus}
				errorMessage={errorText}
				maskChar=""
			/>
		) : (
			<Field
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				errorMessage={errorText}
				disabled={disabled}
				placeholder={placeholder}
				label={label}
			/>
		);
	};

	return <>{handleField()}</>;
};

export default Input;
